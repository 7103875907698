// External Libraries
import { useMemo } from 'react'

// Internal Modules
// Global Utilities and Constants
import { createDocuments } from '@/utils/documentsUtils'

// Components
import DataTable from '@/components/Table/data-table'
import { DataTableToolbar } from '@/components/ManageDocuments/table/data-table-toolbar'
import { CoursesTableSkeleton } from '@/components/Skeletons/CoursesTableSkeleton'

// Columns for DataTable
import columns from '@/components/ManageDocuments/table/columns'

// Hooks and Jotai Atoms
import useCourseDocuments from '@/hooks/documents/useCourseDocuments'

//

export default function ManageDocuments() {
   let content: JSX.Element = <></>
   const { data, loading, pending } = useCourseDocuments()
   const documentData = useMemo(() => {
      if (data || !loading || !pending) {
         return createDocuments(data)
      }
      return null
   }, [data])

   if (loading || (pending && !documentData)) {
      content = <CoursesTableSkeleton />
   } else if (documentData) {
      content = <DataTable columns={columns} data={documentData} DataTableToolbar={DataTableToolbar} />
   }

   return (
      <div className="flex flex-col h-full w-full justify-center items-center ">
         <div className="w-full pt-4">{content}</div>
      </div>
   )
}
