import { Skeleton } from '@/components/ui/skeleton'

export default function ChatSkeleton({ length }: { readonly length: number }) {
   return (
      <>
         {Array.from({ length }).map((_, index) => (
            <div
               key={index}
               className={`flex items-center ${index % 2 === 0 ? 'space-x-4' : 'space-x-reverse space-x-4 flex-row-reverse'}`}
            >
               <Skeleton className="h-12 w-12 rounded-full" />
               <div className="space-y-2">
                  <Skeleton className="h-4 w-[150px]" />
                  <Skeleton className="h-40 w-[450px]" />
               </div>
            </div>
         ))}
      </>
   )
}
