import { useEffect, useState } from 'react'

import { InteractionStatus, InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { useSetAtom } from 'jotai'
import { Outlet, useNavigate } from 'react-router-dom'

import { getUserRoleFromDB } from '@/api'
import { queryClient } from '@/App'
import { loginRequest } from '@/auth/authConfig'
import { CustomNavigationClient } from '@/auth/NavigationClient'
import { acquireToken } from '@/auth/utils'
import Navbar from '@/components/Navbar/Navbar'
import { ThemeProvider } from '@/components/theme-provider'
import { Toaster as Sonner } from '@/components/ui/sonner'
import { userAtom } from '@/store/store'
import { User } from '@/types'

export default function Layout() {
   const { instance, inProgress } = useMsal()
   // The next 3 lines are optional. This is how you configure MSAL to take advantage
   // of the router's navigate functions when MSAL redirects between pages in your app
   const navigate = useNavigate()
   const navigationClient = new CustomNavigationClient(navigate)
   instance.setNavigationClient(navigationClient)
   const [isLoading, setIsLoading] = useState(true)
   const setUser = useSetAtom(userAtom)

   useEffect(() => {
      const loadData = async () => {
         const data = await acquireToken()
         if (!data?.idToken || !data.userId || !data.userName) {
            console.log('No idToken or userId found', data)
            setIsLoading(false)
            return
         }
         queryClient.setQueryDefaults(['auth-credentials'], {
            queryFn: ({ signal }) => getUserRoleFromDB(data.userId, signal),
         })
         const userRole = await queryClient.fetchQuery({
            queryKey: ['user-roles'],
            queryFn: ({ signal }) => getUserRoleFromDB(data.userId, signal),
         })

         setUser((prev) => ({ ...prev, ...data, ...userRole }) as User)
         setIsLoading(false)
      }
      if (inProgress === InteractionStatus.None) {
         loadData().catch((e) => {
            console.error(e)
         })
      } else {
         setIsLoading(true)
      }
   }, [inProgress, instance])

   // don't render anything until we have the user data, this ensures that the user is authenticated and authorized before rendering the app.
   // this is necessary to prevent ui flicker in the nav bar and potentially other components that rely on the user data mainly for authorization.
   if (isLoading) {
      return null
   }

   const authRequest = {
      ...loginRequest,
   }

   return (
      <MsalAuthenticationTemplate
         interactionType={InteractionType.Silent}
         authenticationRequest={authRequest}
         errorComponent={() => <div></div>}
         loadingComponent={() => <div></div>}
      >
         <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
            <Navbar />
            <Sonner richColors closeButton visibleToasts={50} duration={5000} />
            <div className="container py-8">
               <Outlet />
            </div>
         </ThemeProvider>
      </MsalAuthenticationTemplate>
   )
}
