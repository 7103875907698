import { Dispatch, FC, ReactNode } from 'react'
import { Sheet, SheetContent, SheetTitle, SheetDescription } from '@/components/ui/sheet'
import { Link, useLocation } from 'react-router-dom'
import { ModeToggle } from '@/components/mode-toggle'
import {
   NavigationMenu,
   NavigationMenuContent,
   NavigationMenuIndicator,
   NavigationMenuItem,
   NavigationMenuLink,
   NavigationMenuList,
   NavigationMenuTrigger,
   NavigationMenuViewport,
   navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { UserRoleIndex } from '@/types'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'
import { buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'
type MobileNavProps = Readonly<{
   isOpen: boolean
   setIsOpen: Dispatch<React.SetStateAction<boolean>>
}>

type NavLinkProps = {
   href: string
   children?: ReactNode
   className?: string
   onClick?: () => void
}

const NavLink: FC<NavLinkProps> = ({ href, children, className, ...props }) => {
   const pathname = useLocation().pathname
   const isActive = href === pathname

   return (
      <NavigationMenuLink asChild active={isActive}>
         <Link
            to={href}
            className={cn(
               buttonVariants({
                  variant: 'ghost',
                  size: 'sm',
                  className:
                     'group inline-flex w-max items-center justify-center text-foreground focus:bg-accent/90 data-[active]:bg-accent data-[active]:text-primary',
               }),
               className,
            )}
            {...props}
         >
            {children}
         </Link>
      </NavigationMenuLink>
   )
}

export default function MobileNavItems({ isOpen, setIsOpen }: MobileNavProps) {
   const user = useAtomValue(userAtom)

   return (
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
         <VisuallyHidden.Root>
            <SheetTitle aria-describedby="mobile-menu" className="">
               Mobile Menu
            </SheetTitle>
         </VisuallyHidden.Root>
         <SheetContent aria-describedby="sheet-mobile-menu" id="sheet-content" side="left" className="flex flex-col">
            <NavigationMenu className="flex items-start">
               <NavigationMenuList
                  id="mobile-nav-items"
                  className="flex flex-col items-start justify-between h-fit gap-10 my-5 "
               >
                  <NavigationMenuItem>
                     <NavLink onClick={() => setIsOpen(false)} href="/documents">
                        Manage Documents
                     </NavLink>
                  </NavigationMenuItem>

                  <NavigationMenuItem>
                     <NavLink onClick={() => setIsOpen(false)} href="/courses">
                        Manage Courses
                     </NavLink>
                  </NavigationMenuItem>

                  {user.userRoleIndex! <= UserRoleIndex.Admin && (
                     <NavigationMenuItem>
                        <NavLink onClick={() => setIsOpen(false)} href="/users">
                           Manage Users
                        </NavLink>
                     </NavigationMenuItem>
                  )}

                  <NavigationMenuItem>
                     <NavLink onClick={() => setIsOpen(false)} href="/conversations">
                        Conversations
                     </NavLink>
                  </NavigationMenuItem>

                  {user.userRoleIndex === UserRoleIndex.Dev && (
                     <NavigationMenuItem>
                        <NavLink onClick={() => setIsOpen(false)} href="/logs">
                           Logs
                        </NavLink>
                     </NavigationMenuItem>
                  )}
               </NavigationMenuList>
            </NavigationMenu>
            <VisuallyHidden.Root>
               <SheetDescription>This is the mobile menu for navigating the application</SheetDescription>
            </VisuallyHidden.Root>
            <ModeToggle isMobile />
         </SheetContent>
      </Sheet>
   )
}
