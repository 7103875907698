import { cn } from '@/lib/utils'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { LucideProps } from 'lucide-react'
export const HoverEffect = ({
   data,
   className,
}: {
   data: {
      icon: React.ForwardRefExoticComponent<Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>>
      title: string
      description: string
      items: string[]
   }[]
   className?: string
}) => {
   const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

   return (
      <div className={cn('grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap:8 xl:gap-10', className)}>
         {data.map((dataPoint, idx) => (
            <Card
               key={dataPoint?.title}
               className="relative group block p-2 border-none"
               onMouseEnter={() => setHoveredIndex(idx)}
               onMouseLeave={() => setHoveredIndex(null)}
            >
               <AnimatePresence>
                  {hoveredIndex === idx && (
                     <motion.span
                        className="absolute inset-0 bg-primary block rounded-3xl"
                        layoutId="hoverBackground"
                        initial={{ opacity: 0 }}
                        animate={{
                           opacity: 1,
                           transition: { duration: 0.15 },
                        }}
                        exit={{
                           opacity: 0,
                           transition: { duration: 0.15, delay: 0.2 },
                        }}
                     />
                  )}
               </AnimatePresence>
               <div className="flex flex-col rounded-2xl p-4 size-full overflow-hidden bg-background border text-pretty border-primary relative z-20">
                  <div className="relative z-50">
                     <div className="p-4">
                        <CardHeader>
                           <div className="flex flex-row items-center text-primary">
                              <dataPoint.icon className="mr-2" />
                              <CardTitle className={cn('flex items-center font-bold tracking-wide', className)}>
                                 {dataPoint.title}
                              </CardTitle>
                           </div>
                           <CardDescription className={cn('mt-8 tracking-wide leading-relaxed text-sm', className)}>
                              {dataPoint.description}
                           </CardDescription>
                        </CardHeader>
                        <CardContent>
                           <ul className="list-disc pl-5">
                              {dataPoint.items.map((item) => (
                                 <li key={item}>{item}</li>
                              ))}
                           </ul>
                        </CardContent>
                     </div>
                  </div>
               </div>
            </Card>
         ))}
      </div>
   )
}
