import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { Course } from '@/types'
import { addCourseToDB } from '@/api'
import { toast } from 'sonner'
import { queryClient } from '@/App'

/**
 * Custom hook for adding a new course to the database using a mutation operation.
 * This hook encapsulates the logic for adding a course, including handling success and error states.
 * It leverages the `useMutation` hook from React Query for asynchronous data mutation.
 *
 * @returns {UseMutationResult<boolean, unknown, Course, unknown>} An object containing several utilities to manage the mutation state and control:
 * - `mutate`: A function to trigger the mutation with the new course data as its argument.
 * - `isLoading`: A boolean indicating if the mutation is currently in progress.
 * - `isSuccess`: A boolean indicating if the last mutation was successful.
 * - `isError`: A boolean indicating if the last mutation resulted in an error.
 * - `data`: The result of the mutation on success, indicating the operation's success status.
 * - `error`: The error object if the mutation fails, providing details about the failure.
 *
 * The hook automatically handles success and error notifications using toast messages.
 * On successful addition of a course, it invalidates and refetch the queries related to the courses list,
 * ensuring the UI is updated with the latest data.
 */
const useCourseMutation = (): UseMutationResult<boolean, unknown, Course, unknown> => {
   return useMutation({
      mutationFn: (newCourse: Course) => addCourseToDB(newCourse),
      onSuccess: (data, variables) => {
         toast.success('Course added successfully!', {
            description: 'Course has been added to the database: ' + variables.courseName,
         })
         queryClient.invalidateQueries({ queryKey: ['courses', variables.createdBy] })
      },
      onError: (error) => {
         toast.error("Couldn't add course", {
            description: error instanceof Error ? error.message : 'Please try again',
         })
      },
   })
}

export default useCourseMutation
