// External libraries
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useAtomValue } from 'jotai'

// Components
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import {
   MultiSelector,
   MultiSelectorContent,
   MultiSelectorInput,
   MultiSelectorItem,
   MultiSelectorList,
   MultiSelectorTrigger,
} from '@/components/ui/multi-select'
// Assets

// Internal modules
import { Course } from '@/types'

// Hooks and Jotai Atoms
import { userAtom } from '@/store/store'
import useCourseMutation from '@/hooks/courses/useCourseMutation'
import useUsersList from '@/hooks/users/useUsersList'

export const formSchema = z.object({
   courseName: z.string().regex(/^[A-Za-z]{3}\d{3}[A-Za-z]{3}$/, {
      message: 'Course name must be 3 letters followed by 3 digits and then 3 letters.',
   }),
   instructors: z.array(z.string().email()).optional(),
})

export function NewCourseForm() {
   const user = useAtomValue(userAtom)
   const { data: usersList } = useUsersList()
   const { mutate: addCourse, isPending } = useCourseMutation()

   const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
         courseName: '',
         instructors: [],
      },
      mode: 'onTouched',
   })

   const onResetForm = () => {
      form.reset()
   }

   const onSubmit = async (values: z.infer<typeof formSchema>) => {
      const newCourse: Course = {
         courseName: values.courseName.toUpperCase(),
         createdBy: user.userId,
         assignedTo: values.instructors,
      }
      addCourse(newCourse)
      form.reset()
   }

   return (
      <Form {...form}>
         <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="flex flex-row gap-4">
               <div className="flex flex-col gap-5 flex-grow">
                  <FormField
                     control={form.control}
                     name="courseName"
                     render={({ field }) => (
                        <FormItem>
                           <div className="flex flex-row justify-center items-center gap-1">
                              <FormLabel className="flex text-left w-[25%]">
                                 Course Name<strong className="text-destructive">*</strong>
                              </FormLabel>
                              <FormControl className="flex w-[75%]">
                                 <Input placeholder="e.g ABC123NAA" {...field} />
                              </FormControl>
                           </div>
                           <FormDescription></FormDescription>
                           <div className="h-4">
                              {form.getValues('courseName') !== '' && <FormMessage className="text-xs" />}
                           </div>
                        </FormItem>
                     )}
                  />
                  <FormField
                     control={form.control}
                     name="instructors"
                     render={({ field }) => (
                        <FormItem className="w-full">
                           <div className="flex flex-row justify-center items-center gap-1">
                              <FormLabel className="flex text-left w-[25%]">Assign Instructors</FormLabel>
                              <MultiSelector
                                 className=" flex w-[75%]"
                                 onValuesChange={field.onChange}
                                 values={field.value || []}
                              >
                                 <MultiSelectorTrigger>
                                    <MultiSelectorInput
                                       disabled={!usersList}
                                       placeholder={field.value?.at(0) ? '' : 'Find Instructors'}
                                    />
                                 </MultiSelectorTrigger>
                                 <MultiSelectorContent>
                                    <MultiSelectorList>
                                       {usersList?.map(
                                          (user) =>
                                             user.role === 'Instructor' && (
                                                <MultiSelectorItem key={user.userId} value={user.userId}>
                                                   <div className="flex items-center space-x-2">
                                                      <span>{user.userId}</span>
                                                   </div>
                                                </MultiSelectorItem>
                                             ),
                                       )}
                                    </MultiSelectorList>
                                 </MultiSelectorContent>
                              </MultiSelector>
                           </div>
                        </FormItem>
                     )}
                  />
               </div>
            </div>
            <div className="flex flex-col">
               <Separator className="mb-3" />
               <div className="flex flex-row gap-3">
                  <Button type="submit" className="w-full" disabled={!form.formState.isValid}>
                     {isPending ? 'Submitting...' : 'Submit'}
                  </Button>
                  <Button type="reset" disabled={isPending} className="w-full" variant="outline" onClick={onResetForm}>
                     Reset
                  </Button>
               </div>
            </div>
         </form>
      </Form>
   )
}
