import { ArrowDownIcon, ArrowUpIcon, CaretSortIcon, EyeNoneIcon } from '@radix-ui/react-icons'
import { Column } from '@tanstack/react-table'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
   DropdownMenu,
   DropdownMenuContent,
   DropdownMenuItem,
   DropdownMenuSeparator,
   DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
   readonly column: Column<TData, TValue>
   readonly title: string
   readonly disableHide?: boolean
   readonly disableSort?: boolean
}

export function DataTableColumnHeader<TData, TValue>({
   column,
   title,
   disableHide,
   disableSort,
   className,
}: DataTableColumnHeaderProps<TData, TValue>) {
   const getSortIcon = () => {
      if (column.getIsSorted() === 'desc') {
         return <ArrowDownIcon className="ml-2 h-4 w-4" />
      } else if (column.getIsSorted() === 'asc') {
         return <ArrowUpIcon className="ml-2 h-4 w-4" />
      } else {
         return <CaretSortIcon className="ml-2 h-4 w-4" />
      }
   }

   if (!column.getCanSort()) {
      return <div className={cn(className)}>{title}</div>
   }

   return (
      <div className={cn('flex items-center space-x-2', className)}>
         <DropdownMenu>
            <DropdownMenuTrigger asChild>
               <Button variant="ghost" size="sm" className="-ml-3 h-8 data-[state=open]:bg-accent">
                  <span>{title}</span>
                  {getSortIcon()}
               </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
               {!disableSort && (
                  <>
                     <DropdownMenuItem aria-label="Sort ascending" onClick={() => column.toggleSorting(false)}>
                        <ArrowUpIcon aria-hidden="true" className="mr-2 size-3.5 text-muted-foreground/70" />
                        Asc
                     </DropdownMenuItem>
                     <DropdownMenuItem aria-label="Sort descending" onClick={() => column.toggleSorting(true)}>
                        <ArrowDownIcon aria-hidden="true" className="mr-2 size-3.5 text-muted-foreground/70" />
                        Desc
                     </DropdownMenuItem>
                  </>
               )}
               {!disableHide && (
                  <div>
                     <DropdownMenuSeparator />
                     <DropdownMenuItem aria-label="Hide column" onClick={() => column.toggleVisibility(false)}>
                        <EyeNoneIcon aria-hidden="true" className="mr-2 size-3.5 text-muted-foreground/70" />
                        Hide
                     </DropdownMenuItem>
                  </div>
               )}
            </DropdownMenuContent>
         </DropdownMenu>
      </div>
   )
}
