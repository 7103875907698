import {
   Dialog,
   DialogContent,
   DialogDescription,
   DialogHeader,
   DialogTitle,
   DialogFooter,
   DialogTrigger,
} from '@/components/ui/dialog'
import { FilePenLine } from 'lucide-react'
import { Button } from '@/components/ui/button'

import useDocumentData from '@/hooks/documents/useDocumentData'
import { Badge } from '@/components/ui/badge'
import TextSkeleton from '@/components/Skeletons/TextSkeleton'
import { useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import DocViewerBlock from '@/components/ManageDocuments/DocViewer/DocViewerBlock'

export default function DocumentDataDialog({
   courseName,
   fileName,
}: Readonly<{ courseName: string; fileName: string }>) {
   const [pageIndex, setPageIndex] = useState(0)
   const [selectedTab, setSelectedTab] = useState('chunks')
   const { data, isError, isLoading } = useDocumentData(courseName, fileName, pageIndex)
   let content = <></>

   if (isError && !data) {
      content = <p>Error fetching document data</p>
   }

   if (isLoading && !data) {
      content = <TextSkeleton length={20} />
   }

   if (data) {
      content = (
         <>
            <TabsContent className="h-full w-full overflow-auto" value="chunks">
               <div className="p-4 h-full w-full overflow-auto rounded-md antialiased font-semibold break-words text-balance">
                  {data.chunkData.map((item) => (
                     <div key={item.chunk} className="flex flex-col size-fit gap-4">
                        <div className="flex flex-col justify-between">
                           <Badge className="size-fit">Chunk {item.chunk}:</Badge>
                           <span>{item.content}</span>
                        </div>
                     </div>
                  ))}
               </div>
            </TabsContent>
            <TabsContent className="flex-1 flex h-full w-full overflow-auto" value="file">
               <DocViewerBlock docUrl={data.sasURL} className="" />
            </TabsContent>
         </>
      )
   }

   return (
      <Dialog>
         <DialogTrigger asChild>
            <FilePenLine className="w-5 h-5" />
         </DialogTrigger>
         <DialogContent className="flex flex-col h-[95vh] max-h-fit max-w-[85vw]">
            <DialogHeader className=" !text-left">
               <DialogTitle>Document Data</DialogTitle>
               <DialogDescription>
                  {courseName} - {fileName}
               </DialogDescription>
            </DialogHeader>
            <Tabs value={selectedTab} onValueChange={setSelectedTab} className="h-[40rem] w-full">
               <TabsList className="w-fit">
                  <TabsTrigger value="chunks">Chunks</TabsTrigger>
                  <TabsTrigger value="file">File</TabsTrigger>
               </TabsList>
               {content}
            </Tabs>
            {selectedTab === 'chunks' && (
               <DialogFooter className="w-full mt-12">
                  <div className="w-full flex justify-center items-center gap-3">
                     <Button
                        disabled={pageIndex === 0}
                        onClick={() => setPageIndex((prevPage) => prevPage - 1)}
                        variant="secondary"
                        size="icon"
                     >
                        <ChevronLeftIcon />
                     </Button>
                     <span>Page {pageIndex + 1}</span>
                     <Button
                        disabled={pageIndex + 1 === data?.pageCount}
                        onClick={() => setPageIndex((prevPage) => prevPage + 1)}
                        variant="secondary"
                        size="icon"
                     >
                        <ChevronRightIcon />
                     </Button>
                  </div>
               </DialogFooter>
            )}
         </DialogContent>
      </Dialog>
   )
}
