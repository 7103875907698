import { useQuery } from '@tanstack/react-query'
import { readConversationFromDB } from '@/api' // replace with actual import

/**
 * Custom hook for reading a specific conversation from the database.
 * Utilizes `@tanstack/react-query` for data fetching and caching.
 *
 * @param {string} conversationId - The ID of the conversation to be fetched.
 * @returns {object} An object containing the query result, including:
 * - `data`: The conversation data.
 * - `isLoading`: A boolean indicating whether the query is loading.
 * - `error`: The error object if the query fails.
 * - Other methods provided by `useQuery` for query control.
 *
 * The `queryKey` is set to ['conversation', conversationId] to uniquely identify this query's cache entry.
 * The `queryFn` is responsible for executing the fetch operation, with the `signal` parameter used to optionally cancel the request.
 */
const useReadConversation = (conversationId: string) => {
   return useQuery({
      queryKey: ['conversation', conversationId],
      queryFn: ({ queryKey, signal }) => readConversationFromDB(queryKey[1], signal),
   })
}

export default useReadConversation
