import { atom } from 'jotai'
import { User, UserRoles } from '@/types'

export const userAtom = atom<User>({
   userId: '',
   userRole: UserRoles.Instructor, // user is an instructor by default
   userName: '',
   idToken: '',
   isAdmin: false, // user is not admin by default
   userRoleIndex: undefined, // user is a undefined user by default
})
