// External libraries
import { StrictMode, useMemo } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { MsalProvider } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'

// Global utilities and constants
import { userAtom } from '@/store/store'
import { UserRoleIndex } from '@/types'
import { authorizationLoader } from '@/auth/utils'
import useMsalEventCallback from '@/auth/hooks/useMsalEventCallback'

// Components & Pages
import Home from '@/pages/Home'
import ManageDocuments from '@/pages/ManageDocuments'
import ManageCourses from '@/pages/ManageCourses'
import ManageUsers from '@/pages/ManageUsers'
import PageNotFound from '@/pages/PageNotFound'
import Layout from '@/Layout'
import LogFileData from '@/components/MonitorLogs/LogFileData'
import ConversationData from '@/components/Conversations/ConversationData'
import Logs from '@/pages/Logs'
import Conversations from '@/pages/Conversations'

export const queryClient = new QueryClient()

type AppProps = Readonly<{
   pca: IPublicClientApplication
}>

const Pages = () => {
   useMsalEventCallback()
   const user = useAtomValue(userAtom)
   const router = useMemo(
      () =>
         createBrowserRouter([
            {
               path: '/',
               element: <Layout />,
               children: [
                  { index: true, element: <Home /> },
                  {
                     path: 'documents',
                     element: <ManageDocuments />,
                     loader: () => authorizationLoader(user.userRoleIndex!, UserRoleIndex.Instructor),
                  },
                  {
                     path: 'courses',
                     element: <ManageCourses />,
                     loader: () => authorizationLoader(user.userRoleIndex!, UserRoleIndex.Instructor),
                  },
                  {
                     path: 'users',
                     element: <ManageUsers />,
                     loader: () => authorizationLoader(user.userRoleIndex!, UserRoleIndex.Admin),
                  },
                  {
                     path: 'conversations',
                     children: [
                        {
                           index: true,
                           element: <Conversations />,
                           loader: () => authorizationLoader(user.userRoleIndex!, UserRoleIndex.Instructor),
                        },
                        {
                           path: ':id/:title',
                           element: <ConversationData />,
                           loader: () => authorizationLoader(user.userRoleIndex!, UserRoleIndex.Instructor),
                        },
                     ],
                  },
                  {
                     path: 'logs',

                     children: [
                        {
                           index: true,
                           element: <Logs />,
                           loader: () => authorizationLoader(user.userRoleIndex!, UserRoleIndex.Dev),
                        },
                        {
                           path: ':course/:date/:time/:fileName',
                           element: <LogFileData />,
                           loader: () => authorizationLoader(user.userRoleIndex!, UserRoleIndex.Dev),
                        },
                     ],
                  },
                  { path: '*', element: <PageNotFound /> },
               ],
            },
         ]),
      [user.userRoleIndex],
   )

   return <RouterProvider router={router} />
}

function App({ pca }: AppProps) {
   return (
      <StrictMode>
         <MsalProvider instance={pca}>
            <QueryClientProvider client={queryClient}>
               <Pages />
            </QueryClientProvider>
         </MsalProvider>
      </StrictMode>
   )
}

export default App
