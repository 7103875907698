import { updatePromptInDB } from '@/api'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { toast } from 'sonner'
import { queryClient } from '@/App'

/**
 * Custom hook for mutating a course prompt in the database.
 *
 * This hook utilizes the `useMutation` hook from `@tanstack/react-query` to handle the mutation operation
 * of updating a course prompt in the database. Upon successful mutation, it shows a success toast notification
 * and invalidates the query cache for the updated prompt to ensure the UI is updated with the latest data.
 * In case of an error, it displays an error toast notification.
 *
 * @returns {UseMutationResult<boolean, unknown, {courseName: string; prompt: string}, unknown>}
 *          A mutation result object that includes the mutation state and functions to manipulate it.
 */
const useCoursePromptMutation = (): UseMutationResult<
   boolean,
   unknown,
   { courseName: string; prompt: string },
   unknown
> => {
   return useMutation({
      mutationFn: (variables) => updatePromptInDB(variables.courseName, variables.prompt),
      onSuccess: (data, variables) => {
         toast.success('Prompt updated successfully!', {
            description: 'Prompt has been updated for the course: ' + variables.courseName,
         })
         queryClient.invalidateQueries({ queryKey: ['prompt', variables.courseName], exact: true, refetchType: 'all' })
      },
      onError: (error) => {
         toast.error("Couldn't update prompt", {
            description: error instanceof Error ? error.message : 'Please try again',
         })
      },
   })
}

export default useCoursePromptMutation
