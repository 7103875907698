// External libraries
import { Link } from 'react-router-dom'
import { useState } from 'react'
// Components
import MenuItems from '@/components/Navbar/MenuItems/MenuItems'
import { Menu as MenuIcon, LogOut } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { ModeToggle } from '@/components/mode-toggle'

// Assets
import SenecaLogoWhite from '../../assets/Seneca-white.png'
import MobileNavItems from '@/components/Navbar/MenuItems/MobileNavItems'
import { logout } from '@/auth/utils'

export default function Navbar() {
   const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

   return (
      <header className="flex h-16 w-full shrink-0 items-center px-4 justify-evenly bg-primary">
         <div className="flex w-full ">
            <Button
               variant="outline"
               size="icon"
               className="lg:hidden"
               onClick={() => setIsMobileNavOpen((prev) => !prev)}
            >
               <MenuIcon />
               <span className="sr-only">Toggle navigation menu</span>
            </Button>
            <div className="flex w-full lg:w-auto items-center justify-center lg:justify-start">
               <Link id="seneca-logo" className="w-24" to={'/'}>
                  <img src={SenecaLogoWhite} alt="Seneca Logo" className="object-contain w-full h-full box-border" />
               </Link>
            </div>
            <MenuItems />
            <MobileNavItems isOpen={isMobileNavOpen} setIsOpen={setIsMobileNavOpen} />
            <div className="flex ml-auto gap-4">
               <div className="hidden lg:flex">
                  <ModeToggle />
               </div>
               <Button size="icon" variant="ghost" onClick={logout} className="text-primary-foreground">
                  <LogOut />
               </Button>
            </div>
         </div>
      </header>
   )
}
