import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import '@cyntler/react-doc-viewer/dist/index.css'
import './DocViewerBlock.css'

export default function DocViewerBlock({ docUrl, className }: Readonly<{ docUrl: string; className: string }>) {
   const regex = /\.([a-zA-Z0-9]+)\?/
   const match = regex.exec(docUrl)
   const fileType = match ? match[1] : null

   const docs = [
      {
         uri: docUrl,
         fileType: fileType!,
      },
   ]
   return (
      <DocViewer
         className={className + 'bg-red-600'}
         prefetchMethod="GET"
         documents={docs}
         pluginRenderers={DocViewerRenderers}
         config={{
            header: {
               disableHeader: true,
            },
         }}
      />
   )
}
