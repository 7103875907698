// External libraries
import { useMutation, UseMutationResult } from '@tanstack/react-query'

// Services and API calls
import { deleteDocumentFromDB } from '@/api'

// Global utilities and constants
import { queryClient } from '@/App'
import { toast } from 'sonner'

// Hooks and Jotai Atoms
import { userAtom } from '@/store/store'
import { useAtomValue } from 'jotai'

/**
 * Custom hook for deleting course documents from the database.
 * This hook encapsulates the mutation operation required to delete documents,
 * leveraging the `useMutation` hook from React Query for asynchronous data mutation.
 *
 * @returns {UseMutationResult<boolean, Error, string[], unknown>} An object containing several utilities to manage the mutation state and control:
 * - `mutate`: A function to trigger the mutation with an array of file names to be deleted as its argument.
 * - `isLoading`: A boolean indicating if the mutation is currently in progress.
 * - `isSuccess`: A boolean indicating if the last mutation was successful.
 * - `isError`: A boolean indicating if the last mutation resulted in an error.
 * - `data`: The result of the mutation on success, typically a boolean indicating the operation's success.
 * - `error`: The error object if the mutation fails, providing details about the failure.
 *
 * The mutation function (`mutationFn`) is responsible for calling `deleteDocumentFromDB`,
 * which performs the actual deletion operation using the provided array of file names.
 * On successful deletion, a success toast notification is displayed, and relevant queries are invalidated to update the UI.
 * On error, an error toast notification is displayed with details about the failure.
 */
const useDeleteCourseDocumentMutation = (): UseMutationResult<boolean, Error, string[], unknown> => {
   const { isAdmin, userId } = useAtomValue(userAtom)
   return useMutation({
      mutationFn: (filesToDelete: string[]) => deleteDocumentFromDB(filesToDelete),
      onSuccess: () => {
         toast.success('Document/s deleted successfully!', {
            description: 'Document/s have been removed from the database ',
         })
         if (isAdmin) {
            queryClient.invalidateQueries({ queryKey: ['documents', userId, 'admin'] })
         } else {
            queryClient.invalidateQueries({
               queryKey: ['documents', userId],
            })
         }
      },
      onError: (error) => {
         toast.error("Couldn't Deleting Document", {
            description:
               error instanceof Error
                  ? `${error.message}. Please try again or contact support.`
                  : 'Please try again or contact support.',
         })
      },
   })
}

export default useDeleteCourseDocumentMutation
