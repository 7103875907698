// External libraries
import { FC, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'
// Components
import {
   NavigationMenu,
   NavigationMenuContent,
   NavigationMenuIndicator,
   NavigationMenuItem,
   NavigationMenuLink,
   NavigationMenuList,
   NavigationMenuTrigger,
   NavigationMenuViewport,
   navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu'
import { UserRoleIndex } from '@/types'
import { buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'

type NavLinkProps = {
   href: string
   children?: ReactNode
   className?: string
   onClick?: () => void
}

const NavLink: FC<NavLinkProps> = ({ href, className, children, ...props }) => {
   const pathname = useLocation().pathname
   const isActive = href === pathname
   return (
      <NavigationMenuLink asChild active={isActive}>
         <Link
            to={href}
            className={cn(
               buttonVariants({
                  variant: 'ghost',
                  size: 'sm',
                  className:
                     'group inline-flex w-max items-center justify-center text-primary-foreground hover:bg-primary-foreground/90 focus:bg-primary-foreground/90 data-[active]:bg-primary-foreground data-[active]:text-primary',
               }),
               className,
            )}
            {...props}
         >
            {children}
         </Link>
      </NavigationMenuLink>
   )
}

export default function MenuItems() {
   const user = useAtomValue(userAtom)
   return (
      <div className="hidden lg:flex justify-center w-full">
         <NavigationMenu id="main-nav">
            <NavigationMenuList>
               <NavigationMenuItem>
                  <NavLink href="/documents">Manage Documents</NavLink>
               </NavigationMenuItem>

               <NavigationMenuItem>
                  <NavLink href="/courses">Manage Courses</NavLink>
               </NavigationMenuItem>

               {user.userRoleIndex! <= UserRoleIndex.Admin && (
                  <NavigationMenuItem>
                     <NavLink href="/users">Manage Users</NavLink>
                  </NavigationMenuItem>
               )}

               <NavigationMenuItem>
                  <NavLink href="/conversations">Conversations</NavLink>
               </NavigationMenuItem>

               {user.userRoleIndex === UserRoleIndex.Dev && (
                  <NavigationMenuItem>
                     <NavLink href="/logs">Logs</NavLink>
                  </NavigationMenuItem>
               )}
            </NavigationMenuList>
         </NavigationMenu>
      </div>
   )
}
