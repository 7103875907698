'use client'

// External libraries
import { ColumnDef } from '@tanstack/react-table'
import { FileUp, FileCheck, FolderSync } from 'lucide-react'

// Internal modules
import { Document } from '@/types'

// Components
import { DataTableColumnHeader } from '@/components/Table/data-table-column-header'
import { Checkbox } from '@/components/ui/checkbox'
import { Filter } from '@/components/Table/filters'
import DeleteAlertDialog from '@/components/DeleteAlertDialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import DocumentDataDialog from '@/components/ManageDocuments/DocumentDataDialog'
import { buttonVariants } from '@/components/ui/button'

// Hooks
import useDeleteCourseDocumentMutation from '@/hooks/documents/useDeleteCourseDocumentMutation'
import useProcessFiles from '@/hooks/documents/useProcessFiles'

// This type is used to define the shape of our data.
const columns: ColumnDef<Readonly<Document>>[] = [
   {
      id: 'select',
      header: ({ table }) => (
         <Checkbox
            checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
         />
      ),
      cell: ({ row }) => (
         <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
         />
      ),
      enableSorting: false,
      enableHiding: false,
   },
   {
      accessorKey: 'fileName',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="File Name" disableHide />
      },
   },
   {
      accessorKey: 'courseName',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Course Name" />
      },
      cell: ({ table, row }) => {
         const options = Filter({ column: table.getColumn('courseName') })
         const status = options.find((status) => status.value === row.getValue('courseName'))

         if (!status) {
            return null
         }

         return status.label
      },
      filterFn: (row, id, value) => {
         return value.includes(row.getValue(id))
      },
   },
   {
      accessorKey: 'createdBy',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Created By" />
      },
      cell: ({ table, row }) => {
         const options = Filter({ column: table.getColumn('createdBy') })
         const status = options.find((status) => status.value === row.getValue('createdBy'))

         if (!status) {
            return null
         }

         return status.label
      },
      filterFn: (row, id, value) => {
         return value.includes(row.getValue(id))
      },
   },
   {
      accessorKey: 'isIndexed',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Status" />
      },
      cell: ({ table, row }) => {
         const options = Filter({ column: table.getColumn('isIndexed') })
         const status = options.find((status) => status.value === row.getValue('isIndexed'))

         if (!status) {
            return null
         }

         return (
            <TooltipProvider>
               <Tooltip>
                  <TooltipTrigger>
                     {status.label ? (
                        <FileCheck className="w-5 h-5 text-green-500" />
                     ) : (
                        <FileUp className="w-5 h-5 text-amber-500 animate-pulse" />
                     )}
                  </TooltipTrigger>
                  <TooltipContent>
                     {status.label ? 'This document uploaded successfully' : 'This document is still being processed'}
                  </TooltipContent>
               </Tooltip>
            </TooltipProvider>
         )
      },
      filterFn: (row, id, value) => {
         return value.includes(row.getValue(id))
      },
   },
   {
      header: ({ column }) => {
         return <DataTableColumnHeader disableSort column={column} title="Actions" />
      },
      accessorKey: 'actions',
      cell: ({ row }) => {
         const document: Document = {
            fileName: row.getValue('fileName'),
            courseName: row.getValue('courseName'),
            createdBy: row.getValue('createdBy'),
            isIndexed: row.getValue('isIndexed'),
         }

         const { mutate: deleteMutate } = useDeleteCourseDocumentMutation()
         const { mutate: processFilesMutate } = useProcessFiles()

         return (
            <div className="flex flex-row gap-2">
               {document.isIndexed && (
                  <TooltipProvider>
                     <Tooltip>
                        <TooltipTrigger className={`${buttonVariants({ variant: 'ghost' })}`}>
                           <DocumentDataDialog fileName={document.fileName} courseName={document.courseName} />
                        </TooltipTrigger>
                        <TooltipContent>View Document Data</TooltipContent>
                     </Tooltip>
                     <Tooltip>
                        <TooltipTrigger
                           onClick={() =>
                              processFilesMutate([
                                 {
                                    courseName: document.courseName,
                                    fileName: document.fileName,
                                    createdBy: document.createdBy,
                                 },
                              ])
                           }
                           className={`${buttonVariants({ variant: 'ghost', className: 'transition-colors duration-300 ease-out cursor-pointer' })}`}
                        >
                           <FolderSync className="size-5" />
                        </TooltipTrigger>
                        <TooltipContent>Reprocess Document Batching</TooltipContent>
                     </Tooltip>
                     <Tooltip>
                        <TooltipTrigger className={`${buttonVariants({ variant: 'ghost' })}`}>
                           <DeleteAlertDialog
                              handleOnClick={() => deleteMutate([`${document.courseName}/${document.fileName}`])}
                              description={`This action cannot be undone. This will permanently delete the document ${document.fileName} from the database.`}
                           />
                        </TooltipTrigger>
                        <TooltipContent>Delete Document</TooltipContent>
                     </Tooltip>
                  </TooltipProvider>
               )}
            </div>
         )
      },
   },
]

export default columns
