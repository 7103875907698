export default function HeroSectionCentredWithImage() {
   return (
      <div className="relative overflow-hidden">
         <div className="max-w-2xl mx-auto py-6">
            <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">MyTutor Admin Panel</h1>
            <p className="mt-3 text-base lg:text-lg xl:text-xl text-muted-foreground">
               Navigate the MyTutor Admin Panel efficiently. Here&apos;s a quick overview of the main features and
               functionalities to help you manage your courses, documents, conversations, users, and logs effectively.
            </p>
         </div>
      </div>
   )
}
