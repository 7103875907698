// Components
import { Skeleton } from '@/components/ui/skeleton'
import {
   Table,
   TableBody,
   TableCaption,
   TableCell,
   TableFooter,
   TableHead,
   TableHeader,
   TableRow,
} from '@/components/ui/table'

export function CoursesTableSkeleton() {
   return (
      <Table>
         <TableCaption>
            <Skeleton className="h-6 w-full" />
         </TableCaption>
         <TableHeader>
            <TableRow>
               <TableHead className="flex flex-row items-center gap-2">
                  <Skeleton className="h-6 w-24" />
               </TableHead>
               <TableHead>
                  <Skeleton className="h-6 w-24" />
               </TableHead>
            </TableRow>
         </TableHeader>
         <TableBody>
            {[...Array(5)].map((_, index) => (
               <TableRow key={index}>
                  <TableCell className="w-full py-[1.35rem]">
                     <div className="flex flex-row items-center gap-2">
                        <Skeleton className="h-6 w-6" />
                        <Skeleton className="h-6 w-full" />
                     </div>
                  </TableCell>
                  <TableCell>
                     <Skeleton className="h-6 w-24" />
                  </TableCell>
               </TableRow>
            ))}
         </TableBody>
         <TableFooter>
            <TableRow>
               <TableCell colSpan={1}>
                  <div className="flex flex-row items-center gap-2">
                     <Skeleton className="h-6 w-6" />
                     <Skeleton className="h-6 w-24" />
                  </div>
               </TableCell>
               <TableCell className="text-right">
                  <Skeleton className="h-6 w-24" />
               </TableCell>
            </TableRow>
         </TableFooter>
      </Table>
   )
}
