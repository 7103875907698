import { useEffect } from 'react'
import { msalInstance } from '@/index'
import { EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser'
import { useSetAtom } from 'jotai'
import { userAtom } from '@/store/store'

/**
 * Custom hook to handle MSAL event callbacks.
 *
 * This hook listens for MSAL login success events and updates the user state accordingly.
 */
export const useMsalEventCallback = () => {
   const setUser = useSetAtom(userAtom)

   useEffect(() => {
      const callbackId = msalInstance.addEventCallback((event: EventMessage) => {
         if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult
            const account = payload.account
            msalInstance.setActiveAccount(account)
            setUser((prev) => ({
               ...prev,
               userId: account.username,
               userName: account.name ?? '',
               idToken: payload.idToken,
            }))
         }
      })

      return () => {
         if (callbackId) {
            msalInstance.removeEventCallback(callbackId)
         }
      }
   }, [setUser])
}

export default useMsalEventCallback
