import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { listChatLogsFromDB } from '@/api'

type useListChatAPILogsFilesProps = {
   course_name: string
   date: string
   pageIndex: number
   pageSize: number
   time?: string
   fileName?: string
}

/**
 * Custom hook for listing chat API log files from the database based on various filters.
 * This hook leverages the `useQuery` hook from `@tanstack/react-query` for data fetching and caching.
 *
 * @param {Object} params - The parameters for filtering the chat API log files.
 * @param {string} params.course_name - The name of the course to filter logs by.
 * @param {string} params.date - The date to filter logs by.
 * @param {number} params.pageIndex - The current page index for pagination.
 * @param {number} params.pageSize - The number of log entries per page.
 * @param {string} [params.time] - Optional time filter for logs.
 * @param {string} [params.fileName] - Optional file name filter for logs.
 * @returns An object containing the query result. This includes the log files data on success,
 * and provides utilities to manage the query state, such as loading and error states.
 *
 * The `queryFn` dynamically fetches the chat API log files based on the provided filters.
 * If any of the required filters are missing, it returns a resolved promise with default empty data.
 * The `placeholderData` option is used to keep previous data until new data is fetched, improving user experience.
 */
const useListChatAPILogsFiles = ({
   course_name,
   date,
   pageIndex,
   pageSize,
   time,
   fileName,
}: useListChatAPILogsFilesProps) => {
   return useQuery({
      queryKey: ['chat-api-logs', course_name, date, pageIndex, pageSize, time, fileName] as [
         string,
         string,
         string,
         number,
         number,
         string?,
         string?,
      ],
      queryFn: ({ signal }) => {
         // return an empty promise if any of the required fields are missing
         if (!course_name || !date || pageIndex === undefined || pageSize === undefined) {
            return Promise.resolve({ rows: [], pageCount: 0, rowCount: 0 })
         }

         return listChatLogsFromDB({
            course_name,
            date,
            time,
            fileName,
            pageIndex,
            pageSize,
            signal,
         })
      },
      placeholderData: keepPreviousData,
      retry: 0,
   })
}

export default useListChatAPILogsFiles
