import { addUserToDB } from '@/api'
import { queryClient } from '@/App'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { toast } from 'sonner'

type NewUser = {
   userId: string
   userRole: string
}

/**
 * Custom hook for adding a new user to the database.
 * Utilizes React Query's `useMutation` for the add operation, providing a mutation result interface.
 *
 * The hook no longer integrates with the application's global state (using Jotai) to access the current user's ID token.
 * It uses the `addUserToDB` function to perform the actual database operation, passing the new user details.
 *
 * On successful addition, it displays a success toast notification and invalidates the 'users-list' query to refresh the user list.
 * On failure, it displays an error toast notification with the error message or a generic message urging to try again.
 *
 * @returns {UseMutationResult<{ message: string } | { error: string }, unknown, NewUser, unknown>}
 * An object containing the mutation's state and control methods, including:
 * - `mutationFn`: Function to add a new user, taking a `NewUser` object with the user's ID and role.
 * - `onSuccess`: Callback that triggers a success toast and invalidates the 'users-list' query.
 * - `onError`: Callback that triggers an error toast if the addition fails.
 *
 * This hook abstracts the user addition logic and state management, facilitating its use in UI components.
 */
const useUserMutation = (): UseMutationResult<{ message: string } | { error: string }, unknown, NewUser, unknown> => {
   return useMutation({
      mutationFn: (newUser: NewUser) => addUserToDB(newUser.userId, newUser.userRole),
      onSuccess: (_, variables) => {
         toast.success('User added successfully!', {
            description: `User has been added to the database: ${variables.userId} with role ${variables.userRole}`,
         })
         queryClient.invalidateQueries({ queryKey: ['users-list'] })
      },
      onError: (error) => {
         toast.error("Couldn't add user", {
            description: error instanceof Error ? error.message : 'Please try again',
         })
      },
   })
}

export default useUserMutation
