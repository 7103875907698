import { useQuery } from '@tanstack/react-query'
import { getPromptFromDB } from '@/api'

/**
 * Custom hook for fetching a course prompt from the database using React Query.
 *
 * This hook utilizes `useQuery` from `@tanstack/react-query` to fetch a course prompt based on the
 * provided course name. The hook constructs a query with the course name and passes an AbortSignal
 * to the fetch function to handle potential request cancellation. The result of this query is cached
 * and managed by React Query, providing features like background updates and stale data handling.
 *
 * @param {string} courseName - The name of the course for which the prompt is being fetched.
 * @returns {UseQueryResult} The result of the query, which includes the course prompt data, loading state, and any errors.
 */
const useCoursePrompt = (courseName: string) => {
   return useQuery({
      queryKey: ['prompt', courseName],
      queryFn: ({ queryKey, signal }) => getPromptFromDB(queryKey[1], signal),
   })
}

export default useCoursePrompt
