export enum UserRoles {
   Dev = 'Dev',
   Admin = 'Admin',
   Instructor = 'Instructor',
}

export enum UserRoleIndex {
   Dev = 0,
   Admin = 1,
   Instructor = 2,
}

export type User = {
   userId: string
   userRole: UserRoles
   userName: string
   idToken: string
   isAdmin: boolean
   userRoleIndex?: UserRoleIndex
}
