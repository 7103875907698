import { Table } from '@tanstack/react-table'
import { Input } from '@/components/ui/input'
import { DataTableViewOptions } from '@/components/Table/data-table-view-options'
import NewCourseDialog from '@/components/ManageCourses/NewCourseDialog'
import { DataTableFacetedFilter } from '@/components/Table/data-table-faceted-filter'
import { Filter } from '@/components/Table/filters'
import { X } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'
interface DataTableToolbarProps<TData> {
   readonly table: Table<TData>
   readonly className?: string
}

export function DataTableToolbar<TData>({ table, className }: DataTableToolbarProps<TData>) {
   const isFiltered = table.getState().columnFilters.length > 0
   const user = useAtomValue(userAtom)

   return (
      <div className={cn('flex w-full items-center justify-between space-x-2 overflow-auto p-1', className)}>
         <div className="flex flex-1 items-center space-x-2">
            <Input
               placeholder="Find Courses..."
               value={(table.getColumn('courseName')?.getFilterValue() as string) ?? ''}
               onChange={(event) => table.getColumn('courseName')?.setFilterValue(event.target.value)}
               className="h-8 w-40 lg:w-64"
            />
            {table.getColumn('createdBy') && (
               <DataTableFacetedFilter
                  column={table.getColumn('createdBy')}
                  title="Created By"
                  options={Filter({ column: table.getColumn('createdBy') })}
               />
            )}
            {isFiltered && (
               <Button aria-label="Reset filters" variant="ghost" size="sm" onClick={() => table.resetColumnFilters()}>
                  <X className="size-4" aria-hidden="true" />
                  Reset
               </Button>
            )}
         </div>
         <div className="flex items-center gap-2">
            {user.isAdmin && <NewCourseDialog />}
            <DataTableViewOptions table={table} ignoreColumn={0} />
         </div>
      </div>
   )
}
