import {
   AlertDialog,
   AlertDialogAction,
   AlertDialogCancel,
   AlertDialogContent,
   AlertDialogDescription,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogTitle,
   AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Trash2 } from 'lucide-react'

type DialogProps = Readonly<{
   handleOnClick: () => void
   title?: string
   description?: string | React.ReactNode
   icon?: React.ReactNode
   className?: string
}>

export default function DeleteAlertDialog({ handleOnClick, title, description, icon, className }: DialogProps) {
   return (
      <AlertDialog>
         <AlertDialogTrigger asChild className={`${className} transition-colors duration-300 ease-out cursor-pointer`}>
            {icon || <Trash2 className="size-5" />}
         </AlertDialogTrigger>
         <AlertDialogContent>
            <AlertDialogHeader>
               <AlertDialogTitle>{title ?? 'Are you absolutely sure?'}</AlertDialogTitle>
               <AlertDialogDescription>{description}</AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
               <AlertDialogCancel>Cancel</AlertDialogCancel>
               <AlertDialogAction onClick={handleOnClick}>Continue</AlertDialogAction>
            </AlertDialogFooter>
         </AlertDialogContent>
      </AlertDialog>
   )
}
