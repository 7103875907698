// Components
import { CoursesTableSkeleton } from '@/components/Skeletons/CoursesTableSkeleton'
import { toast } from 'sonner'
import DataTable from '@/components/Table/data-table'
import { DataTableToolbar } from '@/components/ManageCourses/table/data-table-toolbar'
import columns from '@/components/ManageCourses/table/columns'
import useCourses from '@/hooks/courses/useCourses'

const ManageCourses = () => {
   let content = <></>

   const {
      data: coursesList,
      isLoading: isFetchCoursesLoading,
      isPending: isFetchCoursesPending,
      isError,
   } = useCourses()

   if (isFetchCoursesLoading || isFetchCoursesPending) {
      content = <CoursesTableSkeleton />
   }

   if (isError) {
      toast.error('An error occurred while fetching courses. Please try again later.')
   }

   if (!coursesList && !isFetchCoursesPending) {
      toast.warning('No courses found. Please add a course to get started.')
   }

   if (coursesList) {
      content = <DataTable columns={columns} data={coursesList} DataTableToolbar={DataTableToolbar} />
   }

   return (
      <div className="flex flex-col h-full w-full justify-center items-center ">
         <section className="w-full pt-4">{content}</section>
      </div>
   )
}

export default ManageCourses
