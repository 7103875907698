import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { Course } from '@/types'
import { deleteCourseFromDB } from '@/api'
import { toast } from 'sonner'
import { queryClient } from '@/App'

/**
 * Custom hook for deleting a course from the database.
 * This hook utilizes a mutation function to delete a specific course from the database.
 * It leverages react-query's useMutation for asynchronous data mutation with optimistic updates.
 *
 * @returns {UseMutationResult<boolean, unknown, Course, unknown>}
 * The return value is a mutation result object that includes the mutation state and functions to manipulate it.
 */
const useCourseDeleteMutation = (): UseMutationResult<boolean, unknown, Course, unknown> => {
   return useMutation({
      mutationFn: (course: Course) => deleteCourseFromDB(course.courseName),
      onSuccess: (data, variables) => {
         toast.success('Course deleted successfully!', {
            description: `Course has been deleted from the database: ${variables.courseName}`,
         })
         queryClient.invalidateQueries({ queryKey: ['courses', variables.createdBy] })
      },
      onError: (error) => {
         toast.error("Couldn't delete course", {
            description: error instanceof Error ? error.message : 'Please try again',
         })
      },
   })
}

export default useCourseDeleteMutation
