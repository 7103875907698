// External libraries
import { useParams } from 'react-router-dom'

// Components
import { ScrollArea } from '@/components/ui/scroll-area'
import { Avatar } from '@/components/ui/avatar'
import { User as UserIcon, BotMessageSquare as AiIcon } from 'lucide-react'
// Hooks and Jotai Atoms
import useReadConversation from '@/hooks/monitor/useReadConversation'
import ChatSkeleton from '@/components/Skeletons/ChatSkeleton'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import stringWidth from 'string-width'

type MessageBlockProps = {
   content: string
   role: string
   time: string
   date: string
}

const MessageBlock = ({ content, role, time, date }: MessageBlockProps) => {
   const isUser = role === 'user'
   return (
      <div className={`flex items-start gap-3 ${isUser && 'justify-end'}`}>
         {!isUser && (
            <Avatar className="w-8 h-8 border justify-center items-center bg-muted ">
               <AiIcon className="text-muted-foreground" />
            </Avatar>
         )}
         <div className="grid gap-1 text-sm">
            <div className={`flex items-center gap-2 ${isUser && 'justify-end'}`}>
               <div className={`${isUser && 'order-2'} font-medium`}>{isUser ? 'User' : 'Assistant'}</div>
               <div className="text-xs text-muted-foreground">{`${date} ${time.slice(0, -4)}`}</div>
            </div>
            <div
               className={`${!isUser ? 'bg-muted' : 'bg-primary text-primary-foreground'} px-3 py-2 rounded-lg max-w-[540px]`}
            >
               <Markdown remarkPlugins={[[remarkGfm, { stringLength: stringWidth }]]}>{content}</Markdown>
            </div>
         </div>
         {isUser && (
            <Avatar className="w-8 h-8 border justify-center items-center bg-muted ">
               <UserIcon className="text-muted-foreground" />
            </Avatar>
         )}
      </div>
   )
}

export default function ConversationData() {
   const { id, title } = useParams()
   let content = <></>

   // TODO this should replace with a error boundary fallback component
   if (!id || !title) {
      return <p>Missing search parameters </p>
   }

   const { data: conversationData, isLoading, error } = useReadConversation(id)

   if (error) {
      content = <p>{error.message}</p>
   }

   if (conversationData) {
      content = (
         <div className="grid gap-4">
            {conversationData?.map((message) => (
               <MessageBlock
                  key={message.messageId}
                  time={message.time}
                  date={message.date}
                  content={message.content}
                  role={message.role}
               />
            ))}
         </div>
      )
   }

   if (isLoading) {
      content = <ChatSkeleton length={4} />
   }

   return (
      <div className="py-4">
         <h1 className="text-2xl text-center font-semibold mb-4">{title}</h1>
         <ScrollArea className=" h-[80vh] rounded-md border">
            <div className="p-4 md:p-6 lg:p-8">
               <div className="grid gap-4">{content}</div>
            </div>
         </ScrollArea>
      </div>
   )
}
