import { useQuery } from '@tanstack/react-query'
import { listCoursesFromDB } from '@/api'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'

/**
 * `useCourses` fetches the authenticated user's course list using their `userId`.
 * It leverages `@tanstack/react-query` for data fetching, synchronization, and caching. The query
 * is identified by a `queryKey` that includes 'courses' and `userId`, optimizing cache management.
 *
 * Data fetching is executed by `queryFn`, calling `listCoursesFromDB` with `userId` and an abort `signal`.
 * This hook simplifies data fetching and state management, offering an easy interface for accessing course data and fetch status.
 *
 * @returns An object containing:
 * - The fetched courses list (`data` property of the response object).
 * - Various status flags (`isLoading`, `isError`, etc.) indicating the state of the fetch operation.
 * - The `error` object, if any errors were encountered during the fetch operation.
 */
const useCourses = () => {
   const { userId } = useAtomValue(userAtom)
   return useQuery({
      queryKey: ['courses', userId],
      queryFn: ({ queryKey, signal }) => listCoursesFromDB(queryKey[1], signal),
   })
}

export default useCourses
