import { UserRoleIndex, UserRoles } from '@/types'
import { refreshAuthTokenAndUpdateUser } from '@/auth/utils'

const API_URL = import.meta.env.VITE_API_URL

/**
 * Asynchronously fetches the role information for a specific user from the API.
 *
 * This function makes a GET request to the API to retrieve the user's role, whether the user is an admin,
 * a list of available roles, the index of the lowest privilege, and the index of the user's role within the
 * available roles. It requires an ID token for authorization, a user ID to specify which user's role to fetch,
 * and an AbortSignal to optionally cancel the request. The function returns a promise that resolves to an object
 * containing the user's role information.
 *
 * @param {string} userId - The ID of the user whose role information is being fetched.
 * @param {AbortSignal} signal - An AbortSignal object that can be used to abort the fetch request.
 * @returns {Promise<{user_role: UserRoles, is_admin: boolean, available_roles: string[], lowest_privilege_index: number, user_role_index: UserRoleIndex}>}
 * A promise that resolves to an object containing the user's role information. If the request is aborted, it resolves to an empty object.
 * If the request fails, it throws an error with a descriptive message.
 */
export const getUserRoleFromDB = async (
   userId: string,
   signal: AbortSignal,
): Promise<
   | {
        userRole: UserRoles
        isAdmin: boolean
        availableRoles: string[]
        lowestPrivilege_index: number
        userRoleIndex: UserRoleIndex
     }
   | undefined
> => {
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/users/get-role?user_id=${encodeURIComponent(userId)}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      const body = await response.json()
      return body
   } catch (error) {
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error(`An error occurred while fetching the user's role`)
   }
}

/**
 * Asynchronously lists users from the API.
 *
 * This function fetches a list of users from a specified API endpoint. It requires an ID token for authorization
 * and an AbortSignal to optionally cancel the request. The function returns a promise that resolves to an array
 * of objects, each containing user roles and a user identifier.
 *
 * @param {AbortSignal} signal - An AbortSignal object that can be used to abort the fetch request.
 * @returns {Promise<{roles: string; user: string}[]>} A promise that resolves to an array of objects,
 * each containing user roles and a user identifier. If the request is aborted, it resolves to an empty array.
 * If the request fails, it throws an error with a descriptive message.
 */
export const listUsersFromDB = async (signal: AbortSignal): Promise<{ role: string; userId: string }[] | undefined> => {
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/users/list`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      const body = await response.json()
      return body
   } catch (error) {
      if (signal.aborted) {
         return undefined
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while listing users from the database')
   }
}

/**
 * Asynchronously adds a user with a specified role to the API.
 *
 * This function makes a POST request to the API to add a user with a specified role. It requires an ID token
 * for authorization, a user ID, and a user role as parameters. The function returns a promise that resolves
 * to an object containing a message about the successful addition of the user or an error message if the
 * operation fails.
 *
 * @param {string} userId - The ID of the user to be added.
 * @param {string} userRole - The role to be assigned to the user.
 * @returns {Promise<{message: string} | {error: string}>} A promise that resolves to an object containing either
 * a success message or an error message.
 */
export const addUserToDB = async (
   userId: string,
   userRole: string,
): Promise<{ message: string } | { error: string }> => {
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(
         `${API_URL}/api/users/add?user_id=${encodeURIComponent(userId)}&user_role=${encodeURIComponent(userRole)}`,
         {
            method: 'POST',
            headers: {
               Authorization: `Bearer ${idToken}`,
            },
         },
      )

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      const body = await response.json()
      return body
   } catch (error) {
      if (error instanceof Error) {
         return error
      }
      throw new Error('An error occurred while adding the user to the database')
   }
}

export const deleteUserFromDB = async (userId: string): Promise<boolean> => {
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/users/delete?user_id=${encodeURIComponent(userId)}`, {
         method: 'DELETE',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      return true
   } catch (error) {
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while deleting the user from the database')
   }
}

/**
 * Asynchronously updates a user's role in the API.
 *
 * This function makes a PUT request to the API to update a user's role. It requires an ID token for authorization,
 * a user ID, and a new user role as parameters. The function returns a promise that resolves to an object containing
 * a message about the successful update of the user's role or an error message if the operation fails.
 *
 * @param {string} userId - The ID of the user whose role is to be updated.
 * @param {string} userRole - The new role to be assigned to the user.
 * @returns {Promise<{message: string} | {error: string}>} A promise that resolves to an object containing either
 * a success message or an error message.
 */
export const updateUserRoleInDB = async (
   userId: string,
   userRole: string,
): Promise<{ message: string } | { error: string }> => {
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(
         `${API_URL}/api/users/update?user_id=${encodeURIComponent(userId)}&user_role=${encodeURIComponent(userRole)}`,
         {
            method: 'PUT',
            headers: {
               Authorization: `Bearer ${idToken}`,
            },
         },
      )

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      const body = await response.json()
      return body
   } catch (error) {
      if (error instanceof Error) {
         return error
      }
      throw new Error('An error occurred while updating the user role in the database')
   }
}
