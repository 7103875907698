// External libraries
import { useMutation, UseMutationResult } from '@tanstack/react-query'

// Global utilities and constants
import { queryClient } from '@/App'
import { toast } from 'sonner'

// Services and API calls
import { addDocumentToDB } from '@/api'

// Hooks and Jotai Atoms
import { userAtom } from '@/store/store'
import { useAtomValue } from 'jotai'

// Types
import { Document } from '@/types/Document'

/**
 * Custom hook to perform a mutation operation for adding a document to the database.
 * Utilizes the `useMutation` hook from React Query to handle the mutation lifecycle.
 * This hook also integrates with the application's global state via Jotai to access the user's ID token.
 *
 * @returns {UseMutationResult<boolean, Error, Document, unknown>} An object containing several utilities to manage the mutation state and control:
 * - `mutate`: A function to trigger the mutation with a `Document` object as its argument.
 * - `isLoading`: A boolean indicating if the mutation is in progress.
 * - `isSuccess`: A boolean indicating if the mutation was successful.
 * - `isError`: A boolean indicating if the mutation failed.
 * - `data`: The mutation result data on success, typically a boolean indicating the operation's success.
 * - `error`: The error object if the mutation fails, providing detailed error information.
 *
 * The mutation function (`mutationFn`) sends the document to be added to the database, using the user's ID token for authentication.
 * On successful addition, it displays a success message and invalidates specific queries related to documents, triggering a refetch to update the UI with the new document.
 * On error, it displays an error message with details about the failure.
 * The success and error handlers also provide feedback via toast notifications.
 */
const useCoursesDocumentsMutation = (): UseMutationResult<boolean, Error, Document, unknown> => {
   const { userId, isAdmin } = useAtomValue(userAtom)
   return useMutation({
      mutationFn: (document: Document) => addDocumentToDB(document),
      onSuccess: (data, variables) => {
         toast.success('Document added successfully!', {
            description: 'Document has been added to the database: ' + variables.fileName,
         })
         if (isAdmin) {
            queryClient.invalidateQueries({ queryKey: ['documents', userId, 'admin'] })
         } else {
            queryClient.invalidateQueries({
               queryKey: ['documents', variables.createdBy, variables.courseName],
            })
         }
      },
      onError: (error) => {
         toast.error("Couldn't add document", {
            description: error instanceof Error ? error.message : 'Please try again',
         })
      },
   })
}

export default useCoursesDocumentsMutation
