import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { MixerHorizontalIcon } from '@radix-ui/react-icons'
import { Table } from '@tanstack/react-table'
import { Button } from '@/components/ui/button'
import {
   DropdownMenu,
   DropdownMenuCheckboxItem,
   DropdownMenuContent,
   DropdownMenuLabel,
   DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu'

interface DataTableViewOptionsProps<TData> {
   readonly table: Table<TData>
   readonly ignoreColumn?: number
}

export function DataTableViewOptions<TData>({ table, ignoreColumn }: DataTableViewOptionsProps<TData>) {
   return (
      <DropdownMenu>
         <DropdownMenuTrigger asChild>
            <Button aria-label="Toggle columns" variant="outline" size="sm" className="ml-auto h-8 lg:flex">
               <MixerHorizontalIcon className="mr-2 size-4 text-primary" />
               View
            </Button>
         </DropdownMenuTrigger>
         <DropdownMenuContent align="end" className="w-40">
            <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {table
               .getAllColumns()
               // index !== ignoreColumn is used to skip the column that is passed to ignoreColumn as the filtering and searching is done on it
               .filter(
                  (column, index) =>
                     index !== ignoreColumn && typeof column.accessorFn !== 'undefined' && column.getCanHide(),
               )
               .map((column) => {
                  return (
                     <DropdownMenuCheckboxItem
                        key={column.id}
                        className="capitalize"
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) => column.toggleVisibility(!!value)}
                     >
                        <span className="truncate">{column.id.replace(/([A-Z])/g, ' $1').trim()}</span>
                     </DropdownMenuCheckboxItem>
                  )
               })}
         </DropdownMenuContent>
      </DropdownMenu>
   )
}
