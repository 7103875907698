import { useQuery } from '@tanstack/react-query'
import { getDocumentDataFromDB } from '@/api'

/**
 * Custom hook for fetching document data using React Query.
 *
 * This hook utilizes the `useQuery` from `@tanstack/react-query` to fetch document data
 * from the database. It constructs a query key using the course name, file name, and page index
 * to uniquely identify the query. The `getDocumentDataFromDB` function is called to perform the actual data
 * fetching, passing the course name, file name, page index, and an AbortSignal for request cancellation.
 *
 * @param {string} courseName - The name of the course to which the document belongs.
 * @param {string} fileName - The name of the file/document to retrieve.
 * @param {number} pageIndex - The index of the page to fetch.
 * @returns {Object} The result of the query, which includes the document data and status information.
 */
const useDocumentData = (courseName: string, fileName: string, pageIndex: number) => {
   return useQuery({
      queryKey: ['document-data', courseName, fileName, pageIndex],
      queryFn: ({ signal }) => getDocumentDataFromDB(courseName, fileName, pageIndex, signal),
   })
}

export default useDocumentData
