import { useQuery } from '@tanstack/react-query'
import { getConversationsCoursesUsers } from '@/api'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'
import { useCallback } from 'react'

const useConversationCoursesUsers = () => {
   const user = useAtomValue(userAtom)

   const select = useCallback((data: [{ courseName: string; users: [string] }] | []) => {
      const userList = Array.from(new Set(data.flatMap((element) => element.users)))
      const courseList = data.map((element) => element.courseName)
      const filterUsers = (selectedUser: string) =>
         data.filter((element) => element.users.includes(selectedUser)).map((element) => element.courseName) ?? []
      const filterCourses = (selectedCourse: string) =>
         data.find((element) => element.courseName === selectedCourse)?.users ?? []
      return { userList, courseList, filterUsers, filterCourses }
   }, [])

   return useQuery({
      queryKey: ['conversations-courses-users', user.userId],
      queryFn: ({ queryKey, signal }) => getConversationsCoursesUsers(queryKey[1], signal),
      select,
   })
}

export default useConversationCoursesUsers
