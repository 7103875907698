import { toast } from 'sonner'
import { useLogsSearchParams } from '@/hooks/monitor/useLogsSearchParams'
import useListChatAPILogsFiles from '@/hooks/monitor/useListChatAPILogsFiles'
import { DataTable as LogsDataTable } from '@/components/MonitorLogs/table/monitor-data-table'
import { CoursesTableSkeleton } from '@/components/Skeletons/CoursesTableSkeleton'
import { default as logsColumns } from '@/components/MonitorLogs/table/columns'
import { useMemo } from 'react'
import LogsSearchForm from '@/components/MonitorLogs/LogsSearchForm'

export default function Logs() {
   const {
      courseName: logsCourseName,
      date: logsDate,
      pageIndex: logsPageIndex,
      pageSize: logsPageSize,
   } = useLogsSearchParams()

   const {
      data: logFileList,
      isLoading: isLogsLoading,
      error: logsError,
   } = useListChatAPILogsFiles({
      course_name: logsCourseName,
      date: logsDate,
      pageIndex: Number(logsPageIndex),
      pageSize: Number(logsPageSize),
   })

   const content = useMemo(() => {
      if (logsError) {
         toast.error(logsError.message)
      } else if (logFileList && logFileList.rows.length !== 0) {
         return <LogsDataTable columns={logsColumns} data={logFileList.rows} rowCount={logFileList?.rowCount ?? 0} />
      } else if (isLogsLoading) {
         return <CoursesTableSkeleton />
      }
      // Default or fallback content
      return <></>
   }, [logsError, logFileList, isLogsLoading])

   return (
      <div className="h-full w-full justify-center items-center ">
         <LogsSearchForm />
         <div className="w-full pt-4">{content}</div>
      </div>
   )
}
