import { Course, Prompt } from '@/types'
import { refreshAuthTokenAndUpdateUser } from '@/auth/utils'

const API_URL = import.meta.env.VITE_API_URL

/**
 * Adds a course to the database.
 *
 * This function sends a POST request to the server to add a new course. It constructs a query string
 * with the course name and creator's information, then sends this data along with the user's ID token
 * in the request headers for authorization.
 *
 * @param {Course} course - The course object containing the course name and creator's information.
 * @returns {Promise<boolean>} A promise that resolves to true if the course was successfully added,
 *                             or throws an error if the request fails or the server responds with an error.
 * @throws {Error} Throws an error if the request fails or the server responds with an error message.
 */
export const addCourseToDB = async (course: Course): Promise<boolean> => {
   const assignedTo = course.assignedTo?.join(',') ?? ''
   const query = `course_name=${encodeURIComponent(course.courseName)}&created_by=${encodeURIComponent(course.createdBy)}&assigned_to=${encodeURIComponent(assignedTo)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()
      const response = await fetch(`${API_URL}/api/courses/add?${query}`, {
         method: 'POST',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
      })

      if (!response.ok) {
         const body = await response.json()
         console.log('Error adding course to DB:', body)
         throw new Error(body.message)
      }

      return true
   } catch (error) {
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while adding the course to the database.')
   }
}

/**
 * Lists courses from the database for a specific user.
 *
 * This function sends a GET request to the server to retrieve a list of courses associated with a given user ID.
 * It includes the user's ID token in the request headers for authorization. An AbortSignal is also passed to
 * allow the request to be cancelled if necessary (e.g., component unmounting in a React application).
 *
 * @param {string} userId - The ID of the user whose courses are to be listed.
 * @param {AbortSignal} signal - An AbortSignal to optionally abort the fetch request.
 * @returns {Promise<Course[]>} A promise that resolves to an array of Course objects if the request is successful,
 *                              or throws an error if the request fails or the server responds with an error.
 * @throws {Error} Throws an error if the request fails, the server responds with an error, or the request is aborted.
 */
export const listCoursesFromDB = async (userId: string, signal: AbortSignal): Promise<Course[] | undefined> => {
   const query = `?user_id=${userId}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/courses/list${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (!response.ok) {
         const body = await response.json()
         console.log('Error listing courses from DB:', body.error)
         throw new Error(body.message)
      }

      return await response.json()
   } catch (error) {
      // it will be true if the request was aborted by react-query
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while listing the courses from the database.')
   }
}

/**
 * Deletes a course from the database.
 * This function sends a DELETE request to the server to remove a specific course identified by its name.
 *
 * @param {string} courseName - The name of the course to be deleted.
 * @returns {Promise<boolean>} A promise that resolves to true if the course was successfully deleted.
 * @throws {Error} Throws an error if the deletion operation fails, including network errors or if the server responds with a non-OK HTTP status.
 */
export const deleteCourseFromDB = async (courseName: string): Promise<boolean> => {
   const query = `course_name=${courseName}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/courses/delete?${query}`, {
         method: 'DELETE',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      return true
   } catch (error) {
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while deleting the course from the database.')
   }
}

/**
 * Retrieves a prompt for a specific course from the database.
 * This function sends a GET request to the server to fetch the prompt associated with the given course name.
 * It supports request cancellation through an AbortSignal.
 *
 * @param {string} courseName - The name of the course for which the prompt is being requested.
 * @param {AbortSignal} signal - An AbortSignal object that allows the request to be cancelled.
 * @returns {Promise<Prompt>} A promise that resolves to the prompt object retrieved from the database.
 * @throws {Error} Throws an error if the request fails, including network errors, if the server responds with a non-OK HTTP status, or if the request is aborted.
 */
export const getPromptFromDB = async (courseName: string, signal: AbortSignal): Promise<Prompt | undefined> => {
   const query = `course_name=${courseName}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/courses/prompt?${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }
      const body = await response.json()

      return body
   } catch (error) {
      // it will be true if the request was aborted by react-query
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while fetching the prompt from the database.')
   }
}

/**
 * Updates a course prompt in the database.
 * @param {string} courseName - The name of the course to update the prompt for.
 * @param {string} prompt - The new prompt to be updated in the database.
 * @returns {Promise<boolean>} A promise that resolves to true if the update was successful.
 * @throws {Error} Throws an error if the update operation fails.
 */
export const updatePromptInDB = async (courseName: string, prompt: string): Promise<boolean> => {
   const query = `course_name=${encodeURIComponent(courseName)}&system_message=${encodeURIComponent(prompt)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/courses/prompt?${query}`, {
         method: 'PUT',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      return true
   } catch (error) {
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while updating the prompt in the database.')
   }
}

/**
 * Updates the assignees of a specific course in the database.
 * This function sends a PUT request to the server to update the list of user IDs assigned to a course.
 * The course is identified by its name, and the assignees are specified as an array of user IDs.
 *
 * @param {string} courseName - The name of the course to update assignees for.
 * @param {string[]} assignees - An array of user IDs to be assigned to the course.
 * @returns {Promise<boolean>} A promise that resolves to true if the assignees were successfully updated in the database.
 * @throws {Error} Throws an error if the update operation fails, including network errors or if the server responds with a non-OK HTTP status.
 */
export const updateAssigneesInDB = async (courseName: string, assignees: string[]): Promise<boolean> => {
   const assigneesString = assignees.join(',')
   const query = `?course_name=${encodeURIComponent(courseName)}&user_ids=${encodeURIComponent(assigneesString)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/courses/update-users${query}`, {
         method: 'PUT',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      return true
   } catch (error) {
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while updating the assignees in the database.')
   }
}
