import { Table } from '@tanstack/react-table'
import { Input } from '@/components/ui/input'
import { DataTableViewOptions } from '@/components/Table/data-table-view-options'
import { cn } from '@/lib/utils'

interface DataTableToolbarProps<TData> {
   readonly table: Table<TData>
   readonly className?: string
}

export function DataTableToolbar<TData>({ table, className }: DataTableToolbarProps<TData>) {
   // this is commented out because we are not doing any filtering in this table yet
   // const isFiltered = table.getState().columnFilters.length > 0

   return (
      <div className={cn('flex w-full items-center justify-between space-x-2 overflow-auto p-1', className)}>
         <div className="flex flex-1 items-center space-x-2">
            <Input
               placeholder="Find Logs by File Name..."
               value={(table.getColumn('fileName')?.getFilterValue() as string) ?? ''}
               onChange={(event) => table.getColumn('fileName')?.setFilterValue(event.target.value)}
               className="h-8 w-40 lg:w-64"
            />
         </div>
         <div className="flex items-center gap-2">
            <DataTableViewOptions table={table} ignoreColumn={0} />
         </div>
      </div>
   )
}
