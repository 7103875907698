// External libraries
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

// Components
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Separator } from '@/components/ui/separator'
import {
   MultiSelector,
   MultiSelectorContent,
   MultiSelectorInput,
   MultiSelectorItem,
   MultiSelectorList,
   MultiSelectorTrigger,
} from '@/components/ui/multi-select'

// Hooks and Jotai Atoms
import useUsersList from '@/hooks/users/useUsersList'
import useAssigneesMutate from '@/hooks/courses/useAssigneesMutate'

export const formSchema = z.object({
   assignedTo: z.array(z.string().email()),
})

type NewCourseFormProps = Readonly<{
   assignedTo: string[]
   courseName: string
   createdBy: string
   closeDialog: () => void
}>

export default function AssigneesForm({ assignedTo, courseName, createdBy, closeDialog }: NewCourseFormProps) {
   const { data: usersList } = useUsersList()
   const updateAssignees = useAssigneesMutate()

   const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
         assignedTo: assignedTo.filter((user) => user !== createdBy) || [],
      },
      mode: 'onTouched',
   })

   const onResetForm = () => {
      assignedTo && form.setValue('assignedTo', assignedTo) // Reset the form to the initial values

      form.reset()
   }

   const onSubmit = async (values: z.infer<typeof formSchema>) => {
      updateAssignees.mutate({ courseName, assignedTo: values.assignedTo })
   }

   if (updateAssignees.isSuccess || updateAssignees.isError) {
      closeDialog()
   }
   return (
      <Form {...form}>
         <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
               control={form.control}
               name="assignedTo"
               render={({ field }) => (
                  <FormItem className="w-full">
                     <FormLabel className="">Assigned Instructors</FormLabel>
                     <MultiSelector onValuesChange={field.onChange} values={field.value || []}>
                        <MultiSelectorTrigger>
                           <MultiSelectorInput
                              disabled={!usersList}
                              placeholder={field.value?.at(0) ? '' : 'Find Instructors'}
                           />
                        </MultiSelectorTrigger>
                        <MultiSelectorContent>
                           <FormControl>
                              <MultiSelectorList>
                                 {usersList?.map(
                                    (user) =>
                                       user.role === 'Instructor' && (
                                          <MultiSelectorItem
                                             key={user.userId}
                                             value={user.userId}
                                             defaultChecked={true}
                                             disabled={user.userId === createdBy}
                                          >
                                             <div className="flex items-center space-x-2">
                                                <span>
                                                   {user.userId}
                                                   {user.userId === createdBy && ` (Course Creator)`}
                                                </span>
                                             </div>
                                          </MultiSelectorItem>
                                       ),
                                 )}
                              </MultiSelectorList>
                           </FormControl>
                        </MultiSelectorContent>
                     </MultiSelector>
                  </FormItem>
               )}
            />
            <FormDescription className="text-sm text-muted-foreground">
               <strong>NOTE:</strong> Course creator cannot be removed and will always be assigned to the this course
            </FormDescription>
            <div className="flex flex-col">
               <Separator className="mb-3" />
               <div className="flex flex-row gap-3">
                  <Button type="submit" className="w-full">
                     {updateAssignees.isPending ? 'Submitting...' : 'Submit'}
                  </Button>
                  <Button type="reset" className="w-full" variant="outline" onClick={onResetForm}>
                     Reset
                  </Button>
               </div>
            </div>
         </form>
      </Form>
   )
}
