'use client'

// External libraries
import { ColumnDef } from '@tanstack/react-table'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'
import { useMutationState } from '@tanstack/react-query'

// Internal modules
import { Course } from '@/types'

// Components
import { DataTableColumnHeader } from '@/components/Table/data-table-column-header'
import { Filter } from '@/components/Table/filters'
import DeleteAlertDialog from '@/components/DeleteAlertDialog'
import PromptDialog from '@/components/ManageCourses/prompt/PromptDialog'
import AssigneesDialog from '@/components/ManageCourses/AssigneesDialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { buttonVariants } from '@/components/ui/button'

// Hooks
import useCourseDeleteMutation from '@/hooks/courses/useCourseDeleteMutation'

// This type is used to define the shape of our data.
const columns: ColumnDef<Readonly<Course>>[] = [
   {
      accessorKey: 'courseName',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Course Name" disableHide />
      },
   },
   {
      accessorKey: 'createdBy',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Created By" />
      },
      cell: ({ table, row }) => {
         const options = Filter({ column: table.getColumn('createdBy') })
         const status = options.find((status) => status.value === row.getValue('createdBy'))

         if (!status) {
            return null
         }

         return status.label
      },
      filterFn: (row, id, value) => {
         return value.includes(row.getValue(id))
      },
   },
   {
      header: ({ column }) => {
         return <DataTableColumnHeader disableSort column={column} title="Actions" />
      },
      accessorKey: 'actions',
      cell: ({ row }) => {
         const user = useAtomValue(userAtom)
         const mutation = useCourseDeleteMutation()
         const course: Course = {
            courseName: row.getValue('courseName'),
            createdBy: row.getValue('createdBy'),
            assignedTo: row.original.assignedTo,
         }
         const mutationKey = ['courses', course.createdBy]

         // TODO IN PROGRESS this is a workaround to get the status of the mutation to
         // TODO show a loading state until the course is deleted to prevent the user from doing multiple requests at the same time
         const deleteCourseStatus = useMutationState({
            // this mutation key needs to match the mutation key of the given mutation (see above)
            filters: { mutationKey, status: 'pending' },
            select: (mutation) => mutation.state.data,
         })

         if (deleteCourseStatus) {
            // console.log("xxxxx", deleteCourseStatus)
         }

         return (
            <div className="flex flex-row gap-2">
               <TooltipProvider>
                  <Tooltip>
                     <TooltipTrigger className={`${buttonVariants({ variant: 'ghost' })}`}>
                        <PromptDialog courseName={course.courseName} />
                     </TooltipTrigger>
                     <TooltipContent>View/Edit Prompt</TooltipContent>
                  </Tooltip>
                  <div>
                     {user.isAdmin && (
                        <>
                           <Tooltip>
                              <TooltipTrigger className={`${buttonVariants({ variant: 'ghost' })}`}>
                                 <AssigneesDialog
                                    assignedTo={course.assignedTo ?? []}
                                    courseName={course.courseName}
                                    createdBy={course.createdBy}
                                 />
                              </TooltipTrigger>
                              <TooltipContent>View/Edit Assignees</TooltipContent>
                           </Tooltip>
                           <Tooltip>
                              <TooltipTrigger className={`${buttonVariants({ variant: 'ghost' })}`}>
                                 <DeleteAlertDialog
                                    description={`This action cannot be undone. This will permanently delete the course ${course.courseName} and remove your data from our servers.`}
                                    handleOnClick={() => mutation.mutate(course)}
                                 />
                              </TooltipTrigger>
                              <TooltipContent>Delete Course</TooltipContent>
                           </Tooltip>
                        </>
                     )}
                  </div>
               </TooltipProvider>
            </div>
         )
      },
   },
]

export default columns
