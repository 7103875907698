'use client'

import { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from '@/components/Table/data-table-column-header'
import { Filter } from '@/components/Table/filters'
import DeleteAlertDialog from '@/components/DeleteAlertDialog'
import UserDialog from '@/components/ManageUsers/UserDialog'
import useUserDeleteMutation from '@/hooks/users/useUserDeleteMutation'
import { buttonVariants } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

// This type is used to define the shape of our data.
export type ManageUsersColumns = Readonly<{
   userId: string
   role: string
}>

export const columns: ColumnDef<ManageUsersColumns>[] = [
   {
      accessorKey: 'userId',
      header: ({ column }) => {
         return <DataTableColumnHeader disableHide column={column} title="Users" />
      },
   },
   {
      accessorKey: 'role',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Role" />
      },
      cell: ({ table, row }) => {
         const options = Filter({ column: table.getColumn('role') })
         const status = options.find((status) => status.value === row.getValue('role'))

         if (!status) {
            return null
         }

         return status.label
      },
      filterFn: (row, id, value) => {
         return value.includes(row.getValue(id))
      },
   },
   {
      accessorKey: 'actions',
      header: ({ column }) => {
         return <DataTableColumnHeader disableSort column={column} title="Actions" />
      },
      cell: ({ row }) => {
         const userId = `${row.getValue('userId')}`
         const role = `${row.getValue('role')}`
         const { mutate } = useUserDeleteMutation()
         return (
            <div className="flex flex-row gap-2">
               <TooltipProvider>
                  <Tooltip>
                     <TooltipTrigger className={`${buttonVariants({ variant: 'ghost' })}`}>
                        <UserDialog currentRole={role} userEmail={userId} editUser />
                     </TooltipTrigger>
                     <TooltipContent>View Document Data</TooltipContent>
                  </Tooltip>
                  <Tooltip>
                     <TooltipTrigger className={`${buttonVariants({ variant: 'ghost' })}`}>
                        <DeleteAlertDialog
                           handleOnClick={() => mutate({ userId: userId })}
                           description={`This action cannot be undone. This will permanently delete the user ${row.getValue('userId')} from the database.`}
                        />
                     </TooltipTrigger>
                     <TooltipContent>Delete User</TooltipContent>
                  </Tooltip>
               </TooltipProvider>
            </div>
         )
      },
   },
]
