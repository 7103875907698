import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { updateUserRoleInDB } from '@/api/users' // Ensure this is the correct import path
import { queryClient } from '@/App'
import { toast } from 'sonner'

type UserRoleUpdateParams = {
   userId: string
   userRole: string
}

/**
 * Custom hook for updating a user's role in the database.
 * Utilizes React Query's `useMutation` for the update operation, providing a mutation result interface.
 *
 * This hook is designed to update a user's role by calling the `updateUserRoleInDB` function,
 * which requires the user's ID and the new role to perform the update.
 *
 * On successful update, it displays a success toast notification and invalidates the 'users-list' query to refresh the user list.
 * On failure, it displays an error toast notification with the error message or a generic message if the error is unknown.
 *
 * @returns {UseMutationResult<{ message: string } | { error: string }, Error, UserRoleUpdateParams, unknown>}
 * An object containing the mutation's state and control methods, including:
 * - `mutationFn`: Function to update the user's role, taking `UserRoleUpdateParams` with the user's ID and new role.
 * - `onSuccess`: Callback that triggers a success toast and invalidates the 'users-list' query.
 * - `onError`: Callback that triggers an error toast if the update fails.
 *
 * This hook abstracts the user role update logic and state management, facilitating its use in UI components.
 */
export const useUpdateUserRole = (): UseMutationResult<
   { message: string } | { error: string },
   Error,
   UserRoleUpdateParams,
   unknown
> => {
   return useMutation<{ message: string } | { error: string }, Error, UserRoleUpdateParams>({
      mutationFn: (userRoleUpdate: UserRoleUpdateParams) =>
         updateUserRoleInDB(userRoleUpdate.userId, userRoleUpdate.userRole),
      onSuccess: (_, variables) => {
         toast.success(`Updated role to ${variables.userRole} for user ${variables.userId}`)
         queryClient.invalidateQueries({ queryKey: ['users-list'] })
      },
      onError: (error) => {
         toast.error(`Failed to update user role: ${error.message || 'Unknown error'}`)
      },
   })
}
