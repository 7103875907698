// External libraries
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

// Components
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

import useUserAvailableRoles from '@/hooks/users/useUserAvailableRoles'
import { useEffect } from 'react'

import { toast } from 'sonner'
import useUserMutation from '@/hooks/users/useUserMutation'
import { useUpdateUserRole } from '@/hooks/users/useUserUpdateRole'

export const formSchema = z.object({
   userEmail: z.string().email({
      message: 'Invalid email format.',
   }),
   role: z.string().min(1, 'Please select at least one role.'),
})
type UserFormProps = Readonly<{
   currentRole?: string
   userEmail?: string
   closeDialog: () => void
}>

export default function UserForm({ closeDialog, currentRole, userEmail }: UserFormProps) {
   const { data, isError: isRolesOptionsError, isPending } = useUserAvailableRoles()
   const addUser = useUserMutation()
   const updateUser = useUpdateUserRole()

   useEffect(() => {
      if (isRolesOptionsError) {
         toast.error('An error occurred when fetching the roles.')
         closeDialog()
      }
   }, [isRolesOptionsError, closeDialog])

   const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
         userEmail: userEmail ?? '',
         role: currentRole ?? '',
      },
      mode: 'onTouched',
   })

   const onSubmit = (data: z.infer<typeof formSchema>) => {
      if (currentRole && userEmail) {
         updateUser.mutate({ userId: data.userEmail, userRole: data.role })
      } else {
         addUser.mutate({ userId: data.userEmail, userRole: data.role })
      }
      closeDialog()
   }

   const onResetForm = () => {
      form.reset()
   }

   return (
      <Form {...form}>
         <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="flex flex-row gap-4">
               <div className="flex flex-col gap-5 flex-grow">
                  <FormField
                     control={form.control}
                     name="userEmail"
                     defaultValue={userEmail}
                     render={({ field }) => (
                        <FormItem>
                           <div className="flex flex-row justify-center items-center">
                              <FormLabel className="flex text-left w-[25%]">User Email</FormLabel>
                              <FormControl className="flex w-[75%]">
                                 <Input
                                    disabled={!!userEmail}
                                    placeholder="e.g username@senecapolytechnic.ca"
                                    {...field}
                                 />
                              </FormControl>
                           </div>
                           <FormDescription></FormDescription>
                           <div className="h-4">
                              {form.getValues('userEmail') !== '' && <FormMessage className="text-xs" />}
                           </div>
                        </FormItem>
                     )}
                  />
                  <FormField
                     control={form.control}
                     name="role"
                     render={({ field }) => (
                        <FormItem className="flex flex-row justify-center items-center">
                           <FormLabel className="flex text-left w-[25%]">Select Roles</FormLabel>
                           <Select onValueChange={field.onChange} defaultValue={field.value}>
                              <FormControl className="flex w-[75%]">
                                 <SelectTrigger>
                                    <SelectValue className="w-full" placeholder="Select roles" />
                                 </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                 {data?.availableRoles?.map((role) => (
                                    <SelectItem key={role} value={role} disabled={role === currentRole}>
                                       {role}
                                    </SelectItem>
                                 ))}
                              </SelectContent>
                           </Select>
                        </FormItem>
                     )}
                  />
               </div>
            </div>
            <div className="flex flex-col">
               <Separator className="mb-3" />
               <div className="flex flex-row gap-3">
                  <Button type="submit" disabled={isPending} className="w-full">
                     Submit
                  </Button>
                  {!currentRole && (
                     <Button type="reset" className="w-full" variant="outline" onClick={onResetForm}>
                        Reset
                     </Button>
                  )}
               </div>
            </div>
         </form>
      </Form>
   )
}
