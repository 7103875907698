import { Document, CourseDocuments } from '@/types'

export const acceptedFileTypes: string[] = [
   'application/pdf', // PDF
   'text/plain', // TXT
   'text/uri-list', // URL //? not sure about this one and how we should handle if someone uploads a URL / drag and drop a website short cut
   'text/markdown', // MD
   'text/html', // HTML
   'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
   'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
]

// for transformToDocuments
type Input = Readonly<{
   files: {
      name: string
      blob: Blob
   }[]
   course: string
   sections: string[]
}>

/**
 * Transforms the given input into an array of Document objects.
 *
 * @param {Input} input - The input object containing sections and files.
 * @param {string} createdBy - The username of the creator.
 *
 * @returns {Document[]} An array of Document objects. Each Document object contains:
 * - courseName: a string that is a combination of the course and section names.
 * - fileName: the name of the file.
 * - createdBy: the username of the creator.
 * - isIndexed: a boolean value indicating whether the document is indexed or not. Default is false.
 * - documentData: the Blob data of the file.
 */
export const transformToDocuments = (input: Input, createdBy: string): Document[] => {
   return input.sections.flatMap((section) => {
      const courseName = input.course + section

      return input.files.map((file) => ({
         courseName,
         fileName: file.name,
         createdBy,
         isIndexed: false,
         documentData: file.blob,
      }))
   })
}

/**
 * Transforms a CourseDocuments object into an array of Document objects.
 *
 * @param {CourseDocuments} coursesObj - An object where each key is a course code and its value is an array of objects,
 * each object representing a document with 'createdBy' and 'fileName' properties.
 *
 * @returns {Document[]} An array of Document objects. Each Document object contains:
 * - courseName: a string representing the course code.
 * - fileName: a string representing the name of the file.
 * - createdBy: a string representing the username of the user who created the document.
 * - documentData: a Blob object representing the data of the document, which is empty for now.
 */
export const createDocuments = (coursesObj: CourseDocuments): Document[] => {
   const documents: Document[] = []

   Object.entries(coursesObj).forEach(([courseCode, docs]) => {
      docs.forEach(({ createdBy, fileName, isIndexed }) => {
         documents.push({
            courseName: courseCode,
            fileName,
            createdBy,
            isIndexed,
            documentData: new Blob(), // empty Blob for now
         })
      })
   })

   return documents
}
