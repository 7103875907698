import HeroSectionCentredWithImage from '@/components/Home/Hero'
import FeatureDescription from '@/components/Home/FeatureDescription'

export default function Home() {
   return (
      <section className="flex flex-col pb-12 gap-12">
         <HeroSectionCentredWithImage />
         <FeatureDescription />
      </section>
   )
}
