import { Configuration, SilentRequest } from '@azure/msal-browser'

export const msalConfig: Configuration = {
   auth: {
      clientId: import.meta.env.VITE_CLIENTID,
      authority: import.meta.env.VITE_AUTHORITY,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
   },
   cache: {
      cacheLocation: 'localStorage',
   },
   system: {
      allowNativeBroker: false, // Disables WAM Broker
   },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: SilentRequest = {
   scopes: ['User.Read'],
}
