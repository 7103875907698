// External libraries
import { useState } from 'react'
// Components
import {
   Dialog,
   DialogContent,
   DialogDescription,
   DialogHeader,
   DialogTitle,
   DialogFooter,
   DialogTrigger,
} from '@/components/ui/dialog'
import { MessageCircleCode } from 'lucide-react'
import PromptForm from '@/components/ManageCourses/prompt/PromptForm'

export default function PromptDialog({
   courseName,
}: Readonly<{
   courseName: string
}>) {
   const [isOpen, setIsOpen] = useState(false)

   return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
         <DialogTrigger asChild>
            <MessageCircleCode className="w-5 h-5" />
         </DialogTrigger>
         <DialogContent>
            <DialogHeader>
               <DialogTitle>System Prompt</DialogTitle>
               {/* do not remove DialogDescription is it is required for html semantics */}
            </DialogHeader>
            <DialogDescription>View or Edit {courseName} system prompt</DialogDescription>
            <section>
               <PromptForm courseName={courseName} closeDialog={() => setIsOpen(false)} />
            </section>
         </DialogContent>
      </Dialog>
   )
}
