'use client'

import { ColumnDef } from '@tanstack/react-table'
import { FileJson } from 'lucide-react'
import { Link } from 'react-router-dom'
import { DataTableColumnHeader } from '@/components/Table/data-table-column-header'
import { buttonVariants } from '@/components/ui/button'

// This type is used to define the shape of our data.
export type MonitorAPIChatLogsColumns = Readonly<{
   courseName: string
   date: string
   time: string
   fileName: string
   viewLog?: string
}>

export const columns: ColumnDef<MonitorAPIChatLogsColumns>[] = [
   {
      accessorKey: 'fileName',
      header: ({ column }) => {
         return <DataTableColumnHeader disableHide column={column} title="File Name" />
      },
   },
   {
      accessorKey: 'time',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Time" />
      },
   },
   {
      accessorKey: 'date',
      header: ({ column }) => {
         return <DataTableColumnHeader disableSort column={column} title="Date" />
      },
   },
   {
      header: ({ column }) => {
         return <DataTableColumnHeader disableSort column={column} title="View Log" />
      },
      accessorKey: 'viewLog',
      cell: ({ row }) => {
         const fileData: MonitorAPIChatLogsColumns = {
            courseName: row.original.courseName,
            date: row.getValue('date'),
            time: row.getValue('time'),
            fileName: row.getValue('fileName'),
         }

         return (
            <Link
               className={buttonVariants({ variant: 'ghost' })}
               to={`${encodeURIComponent(fileData.courseName)}/${encodeURIComponent(fileData.date)}/${encodeURIComponent(fileData.time)}/${encodeURIComponent(fileData.fileName)}`}
            >
               <FileJson className="w-5 h-5" />
            </Link>
         )
      },
   },
]

export default columns
