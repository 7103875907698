// External libraries
import { useParams } from 'react-router-dom'
import { Highlight, themes } from 'prism-react-renderer'

// Components
import { ScrollArea } from '@/components/ui/scroll-area'
import { useTheme } from '@/components/theme-provider'
import ChatSkeleton from '@/components/Skeletons/ChatSkeleton'

// Hooks and Jotai Atoms
import useChatAPILogFile from '@/hooks/monitor/useChatAPILogFile'
import { toast } from 'sonner'

export default function LogFileData() {
   const { course, date, time, fileName } = useParams()
   const { theme } = useTheme()

   let content = <></>

   // TODO this should replace with a error boundary fallback component
   if (!course || !date || !time || !fileName) {
      return <p>No path specified</p>
   }

   const {
      data: chatLogFile,
      isLoading: isChatLogFileLoading,
      isError: isChatLogFileError,
   } = useChatAPILogFile(course, date, time, fileName)

   if (isChatLogFileLoading || !chatLogFile) {
      content = <ChatSkeleton length={10} />
   }

   if (chatLogFile) {
      content = (
         <Highlight
            theme={theme === 'light' ? themes.github : themes.vsDark}
            code={JSON.stringify(chatLogFile, null, 2)}
            language="tsx"
         >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
               <pre style={style} className="whitespace-pre-wrap overflow-x-auto max-w-full py-4 px-2">
                  {tokens.map((line, i) => (
                     <div key={i} {...getLineProps({ line, className: '' })}>
                        <span>{i + 1} </span>
                        {line.map((token) => (
                           <span key={token.content} {...getTokenProps({ token, className: '' })} />
                        ))}
                     </div>
                  ))}
               </pre>
            )}
         </Highlight>
      )
   }

   if (isChatLogFileError) {
      toast.error('Failed to fetch log file data')
   }

   return (
      <div className="py-4">
         <h1 className="text-2xl text-center font-semibold mb-4">
            Showing Logs for <code>{fileName}</code>
         </h1>
         <ScrollArea className=" h-[80vh] rounded-md border p-4">{content}</ScrollArea>
      </div>
   )
}
