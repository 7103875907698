// External Libraries
import { FilePond } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { useAtomValue } from 'jotai'
import { toast } from 'sonner'
import { useEffect } from 'react'

// Components
import {
   Select,
   SelectContent,
   SelectGroup,
   SelectItem,
   SelectLabel,
   SelectTrigger,
   SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import {
   MultiSelector,
   MultiSelectorContent,
   MultiSelectorInput,
   MultiSelectorItem,
   MultiSelectorList,
   MultiSelectorTrigger,
} from '@/components/ui/multi-select'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'

// Hooks and Jotai Atoms
import { userAtom } from '@/store/store'
import useCourses from '@/hooks/courses/useCourses'
import useCoursesDocumentsMutation from '@/hooks/documents/useCourseDocumentMutation'

// Internal Modules
import { Document } from '@/types/Document'
import { acceptedFileTypes, transformToDocuments } from '@/utils/documentsUtils'
import { getCoursesNamesAndSections } from '@/utils/courseUtils'
import { FilePondFile } from 'filepond'

const FormSchema = z.object({
   files: z
      .array(
         z.object({
            name: z.string(),
            blob: z.instanceof(Blob),
         }),
      )
      .nonempty('Upload at least one file'),
   course: z.string({ required_error: 'Course is required' }),
   sections: z.array(z.string()).nonempty('Select at least one section'),
})

type NewDocumentsFormProps = Readonly<{
   closeDialog: () => void
}>

export default function ManageDocumentsForm({ closeDialog }: NewDocumentsFormProps) {
   const user = useAtomValue(userAtom)

   const form = useForm<z.infer<typeof FormSchema>>({
      resolver: zodResolver(FormSchema),
      defaultValues: {
         files: [],
         course: '',
         sections: [],
      },
      mode: 'onTouched',
   })

   // all the courses and sections for the user, admin will have all the courses and sections
   let coursesNamesAndSections: { courseName: string; sections: string[] }[] = []
   const selectedCourse: string | undefined = useWatch({
      control: form.control,
      name: 'course',
      defaultValue: '',
   })

   let selectedCourseSections: string[] | undefined = []

   const { data: coursesList, isSuccess: isCourseSuccess, isError: isCourseError } = useCourses()

   if (isCourseSuccess && coursesList) {
      coursesNamesAndSections = getCoursesNamesAndSections(coursesList)
      selectedCourseSections = selectedCourse
         ? coursesNamesAndSections?.find((course) => course.courseName === selectedCourse)?.sections
         : []
   } else if (isCourseError) {
      toast.error('An error occurred while loading the courses data. Please try again later or refresh the page.')
   }

   const {
      mutateAsync,
      isPending,
      isSuccess: isDocumentMutationSuccess,
      isError: isDocumentMutationError,
   } = useCoursesDocumentsMutation()

   // closes the form dialog when the document is successfully added or an error occurred
   useEffect(() => {
      if (isDocumentMutationSuccess || isDocumentMutationError) {
         closeDialog()
      }
   }, [isDocumentMutationSuccess, isDocumentMutationError])

   const onFileUploadError = (error: string) => {
      console.error('File upload error:', error)
      toast.error('File upload error', { description: error })
   }

   const handleFilePondOnWarning = (errorCode: number) => {
      if (errorCode === 0) {
         onFileUploadError('File upload is limited to 10 files')
      } else {
         onFileUploadError('An error occurred while uploading the file')
      }
   }

   const handleFilePondBeforeAddFile = (item: FilePondFile, filesFromForm: z.infer<typeof FormSchema>['files']) => {
      if (filesFromForm.some((file) => file.name === item.filename)) {
         onFileUploadError('File already exists')
         return false // prevent the file from being added
      }
      if (!acceptedFileTypes.includes(item.file.type)) {
         // note, if the the acceptedFileTypes is updated, the string pram should be updated as well
         onFileUploadError(
            `${item.file.type} Is not supported. Accepted file types: PDF, TXT, URL, MD, HTML, DOCX, PPTX, HTML`,
         )
         return false // prevent the file from being added
      }
      return true // allow the file to be added
   }

   const onSubmit = async (data: z.infer<typeof FormSchema>) => {
      if (!user?.userId) return
      const documents: Document[] = transformToDocuments(data, user.userId)

      for (const document of documents) {
         try {
            await mutateAsync(document)
            form.reset()
         } catch (error) {
            if (error instanceof Error) {
               // ? for debugging purposes
               console.error('An error occurred while submitting the form:', error)
            }
         }
      }
   }

   const onResetForm = () => {
      form.reset()
   }

   return (
      <Form {...form}>
         <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
               control={form.control}
               name="files"
               render={({ field }) => (
                  <FormItem>
                     <FormControl>
                        <FilePond
                           className="h-[20.2rem]"
                           files={field.value.map((file) => file.blob) || []}
                           allowMultiple={true}
                           maxFiles={10}
                           // ? check with the team what if this is a better way to handle the file types
                           // acceptedFileTypes={acceptedFileTypes}
                           // note, if the the acceptedFileTypes is updated, the labelIdle should be updated as well
                           // fileValidateTypeLabelExpectedTypes="Accepted file types: PDF, TXT, URL, MD, HTML, DOCX, PPTX, HTML"
                           onupdatefiles={(fileItems) => {
                              field.onChange(fileItems.map((file) => ({ name: file.filename, blob: file.file })))
                           }}
                           name="files"
                           onwarning={(error) => {
                              handleFilePondOnWarning(error.code)
                           }}
                           beforeAddFile={(item) => handleFilePondBeforeAddFile(item, field.value)}
                           labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span><br/><small>Upload up to 10 files</small>'
                        />
                     </FormControl>
                     <FormDescription>
                        <span className="text-xs">Accepted file types: PDF, TXT, URL, MD, HTML, DOCX, PPTX, HTML</span>
                     </FormDescription>
                  </FormItem>
               )}
            />
            <div className="flex flex-col gap-3">
               <FormField
                  control={form.control}
                  name="course"
                  render={({ field }) => (
                     <FormItem>
                        <FormLabel>Course</FormLabel>
                        <Select
                           name="select-course"
                           onValueChange={(value) => {
                              field.onChange(value)
                              form.resetField('sections')
                           }}
                           defaultValue={field.value}
                           value={field.value}
                        >
                           <FormControl>
                              <SelectTrigger className="w-full">
                                 <SelectValue placeholder="Select Course" />
                              </SelectTrigger>
                           </FormControl>
                           <SelectContent side="bottom">
                              {coursesNamesAndSections.map((course) => (
                                 <SelectItem value={course.courseName} key={course.courseName}>
                                    {course.courseName}
                                 </SelectItem>
                              ))}
                           </SelectContent>
                        </Select>
                     </FormItem>
                  )}
               />
               <FormField
                  control={form.control}
                  name="sections"
                  render={({ field }) => (
                     <FormItem className="w-full">
                        <FormLabel>Select Sections</FormLabel>
                        <MultiSelector onValuesChange={field.onChange} values={field.value}>
                           <MultiSelectorTrigger>
                              <MultiSelectorInput
                                 className={`${!selectedCourse ? 'cursor-not-allowed' : ''}`}
                                 disabled={!selectedCourse}
                                 placeholder="Select sections"
                              />
                           </MultiSelectorTrigger>
                           <MultiSelectorContent>
                              <MultiSelectorList>
                                 {selectedCourseSections?.map((section) => (
                                    <MultiSelectorItem key={section} value={section}>
                                       <div className="flex items-center space-x-2">
                                          <span>{section}</span>
                                       </div>
                                    </MultiSelectorItem>
                                 ))}
                              </MultiSelectorList>
                           </MultiSelectorContent>
                        </MultiSelector>
                     </FormItem>
                  )}
               />
            </div>
            <div className="flex flex-row gap-3">
               <Button disabled={!form.formState.isValid} className="w-full" type="submit">
                  {isPending ? 'Submitting...' : 'Submit'}
               </Button>
               <Button type="reset" className="w-full" variant="outline" onClick={onResetForm}>
                  Reset
               </Button>
            </div>
         </form>
      </Form>
   )
}
