import { useQuery } from '@tanstack/react-query'
import { getUserRoleFromDB } from '@/api/users'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'

/**
 * Custom hook to fetch a user's role information, including available roles, using their user ID.
 * Leverages React Query's `useQuery` for asynchronous data fetching, caching, and automatic updates.
 *
 * @returns The `useQuery` result, including query status (`isLoading`, `isError`, `isSuccess`),
 *          fetched data, and functions for query management.
 *
 * The query, identified by 'user-roles', calls `getUserRoleFromDB`
 * with the user ID and a `signal` for request cancellation. This setup enhances
 * the user experience by avoiding unnecessary network requests and by aborting the fetch if
 * the component unmounts or if a refetch occurs before the previous fetch completes.
 */
const useUserAvailableRoles = () => {
   const { userId } = useAtomValue(userAtom)

   return useQuery({
      queryKey: ['user-roles'],
      queryFn: ({ signal }) => getUserRoleFromDB(userId, signal),
   })
}

export default useUserAvailableRoles
