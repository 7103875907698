// External libraries
import { Moon, Sun } from 'lucide-react'

// Components
import { Button } from '@/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'

// Hooks
import { useTheme } from '@/components/theme-provider'

export function ModeToggle({ isMobile = false }: Readonly<{ isMobile?: boolean }>) {
   const { setTheme } = useTheme()

   return (
      <DropdownMenu>
         <DropdownMenuTrigger asChild>
            <Button
               variant={isMobile ? 'outline' : 'ghost'}
               size="icon"
               className={`${isMobile ? 'text-primary' : 'text-primary-foreground'}`}
            >
               <Sun className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
               <Moon className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
               <span className="sr-only">Toggle theme</span>
            </Button>
         </DropdownMenuTrigger>
         <DropdownMenuContent align={isMobile ? 'start' : 'end'}>
            <DropdownMenuItem onClick={() => setTheme('light')}>Light</DropdownMenuItem>
            <DropdownMenuItem onClick={() => setTheme('dark')}>Dark</DropdownMenuItem>
            <DropdownMenuItem onClick={() => setTheme('system')}>System</DropdownMenuItem>
         </DropdownMenuContent>
      </DropdownMenu>
   )
}
