import { useQuery } from '@tanstack/react-query'
import { listUsersFromDB } from '@/api'

/**
 * Custom hook for fetching a list of users from the database.
 * Utilizes the `useQuery` hook from `@tanstack/react-query` for data fetching and caching.
 *
 * This hook no longer integrates with the application's global state (using Jotai) to access the current user's ID token.
 * It supports request cancellation by passing a `signal` to the `listUsersFromDB` function, which can be used to abort the request
 * if the component unmounts or if a refetch is triggered before the current fetch completes.
 *
 * @returns The result of the `useQuery` call, which includes the status of the query (`isLoading`, `isError`, `isSuccess`),
 *          the data fetched (if successful), and functions to refetch or manipulate the query's state.
 *
 * The `queryKey` is set to ['users-list'] to uniquely identify this query in the cache, allowing for efficient data retrieval
 * and updates across components that use this hook. The hook does not require any parameters, as it internally
 * manages the signal handling for the request.
 */
const useUsersList = () => {
   return useQuery({
      queryKey: ['users-list'],
      queryFn: ({ signal }) => listUsersFromDB(signal),
   })
}

export default useUsersList
