import DataTable from '@/components/Table/data-table'
import { DataTableToolbar } from '@/components/ManageUsers/table/data-table-toolbar'
import { CoursesTableSkeleton } from '@/components/Skeletons/CoursesTableSkeleton'
import { columns } from '@/components/ManageUsers/table/columns'
import useUsersList from '@/hooks/users/useUsersList'
import { toast } from 'sonner'

const ManageUsers = () => {
   const { data: usersList, isLoading, isError } = useUsersList()
   let content = <></>

   if (isError) {
      toast.error('Failed to get users data')
   }

   if (isLoading) {
      content = <CoursesTableSkeleton />
   }

   if (usersList) {
      content = <DataTable columns={columns} data={usersList} DataTableToolbar={DataTableToolbar} />
   }

   return (
      <div className="flex flex-col h-full w-full justify-center items-center ">
         <div className="w-full pt-4">{content}</div>
      </div>
   )
}

export default ManageUsers
