import { MessageSquareText, Users, GraduationCap, FileText, Logs } from 'lucide-react'
import { HoverEffect } from '@/components/ui/card-hover-effect'
const featureData = [
   {
      icon: GraduationCap,
      title: 'Manage Courses',
      description: 'Access and modify course information, system prompts, and assignments.',
      items: [
         'View and search assigned courses in the courses table',
         'View or edit system prompts for each course',
         'Note: Adding courses and assigning multiple instructors are admin-only functions',
      ],
   },
   {
      icon: FileText,
      title: 'Manage Documents',
      description: 'Upload, view, and organize course materials efficiently',
      items: [
         'View and search documents uploaded to your courses',
         'Use the built-in viewer for chunk and file views',
         'Add documents to existing courses (up to 10 files per upload)',
      ],
   },
   {
      icon: MessageSquareText,
      title: 'Conversations',
      description: 'Monitor and analyze student interactions with the Chat APP',
      items: [
         'View conversations from the Chat APP for assigned courses',
         'Inspect answer accuracy and identify common student questions',
      ],
   },
   {
      icon: Users,
      title: 'Manage Users',
      description: 'Admin-only page for user management and access control',
      items: ['Create new users and manage existing accounts', 'Set and modify user roles and access levels'],
   },
   {
      icon: Logs,
      title: 'Logs',
      description: 'Access detailed conversation logs for technical insights',
      items: [
         'Search and view log files of Chat APP conversations',
         'Gain insights into AI model usage and data sources',
      ],
   },
]

export default function FeatureDescription() {
   return <HoverEffect data={featureData} />
}
