// External libraries
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

// Components
import { Skeleton } from '@/components/ui/skeleton'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Separator } from '@/components/ui/separator'
import { Textarea } from '@/components/ui/textarea'

// hooks
import useCoursePrompt from '@/hooks/courses/useCoursePrompt'
import useCoursePromptMutation from '@/hooks/courses/useCoursePromptMutation'

const formSchema = z.object({
   prompt: z.string().min(5).max(5000),
})
type PromptFormProps = Readonly<{
   courseName: string
   closeDialog: () => void
}>

export default function PromptForm({ courseName, closeDialog }: PromptFormProps) {
   const { data: promptText, isError, isLoading } = useCoursePrompt(courseName)
   const currentPrompt = useCoursePromptMutation()
   const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
         prompt: promptText?.systemMessage ?? '',
      },
      mode: 'onTouched',
   })

   const onSubmit = (data: z.infer<typeof formSchema>) => {
      currentPrompt.mutate({ courseName, prompt: data.prompt })
      closeDialog()
   }

   const onResetForm = () => {
      form.reset({ prompt: '' })
   }

   if (promptText) {
      form.setValue('prompt', promptText.systemMessage)
   }

   if (isLoading) {
      return <Skeleton className="h-[23rem] w-full" />
   }

   if (isError || !promptText) {
      return <p>Error Getting the prompt text for {courseName}</p>
   }

   return (
      <Form {...form}>
         <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
               control={form.control}
               name="prompt"
               render={({ field }) => (
                  <FormItem>
                     <FormLabel></FormLabel>
                     <FormControl>
                        <Textarea className="h-[20rem]" placeholder="" {...field} />
                     </FormControl>
                     <FormDescription></FormDescription>
                     <FormMessage className="text-xs" />
                  </FormItem>
               )}
            />
            <div className="flex flex-col">
               <Separator className="mb-3" />
               <div className="flex flex-row gap-3">
                  <Button
                     type="submit"
                     disabled={!form.formState.isValid || form.getValues().prompt === promptText.systemMessage}
                     className="w-full"
                  >
                     Save
                  </Button>
                  <Button type="reset" className="w-full" variant="outline" onClick={onResetForm}>
                     Clear
                  </Button>
               </div>
            </div>
         </form>
      </Form>
   )
}
