// External libraries
import { useState, Suspense } from 'react'
// Components
import {
   Dialog,
   DialogContent,
   DialogDescription,
   DialogHeader,
   DialogTitle,
   DialogFooter,
   DialogTrigger,
} from '@/components/ui/dialog'
import { UsersRound } from 'lucide-react'
import AssigneesForm from '@/components/ManageCourses/AssigneesForm'
import { Skeleton } from '@/components/ui/skeleton'

export default function AssigneesDialog({
   assignedTo,
   courseName,
   createdBy,
}: Readonly<{
   assignedTo: string[]
   courseName: string
   createdBy: string
}>) {
   const [isOpen, setIsOpen] = useState(false)

   return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
         <DialogTrigger asChild>
            <UsersRound className="w-5 h-5" />
         </DialogTrigger>
         <DialogContent>
            <DialogHeader>
               <DialogTitle>Course Instructors</DialogTitle>
               {/* do not remove DialogDescription is it is required for html semantics */}
            </DialogHeader>
            <DialogDescription>View/Edit assignees for {courseName}</DialogDescription>
            <section>
               <Suspense fallback={<Skeleton className="h-[23rem] w-full" />}>
                  <AssigneesForm
                     assignedTo={assignedTo}
                     courseName={courseName}
                     createdBy={createdBy}
                     closeDialog={() => setIsOpen(false)}
                  />
               </Suspense>
            </section>
         </DialogContent>
      </Dialog>
   )
}
