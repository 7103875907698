import { Skeleton } from '@/components/ui/skeleton'

export default function TextSkeleton({ length }: { readonly length: number }) {
   return (
      <div className="overflow-auto">
         {Array.from({ length }).map((_, index) => (
            <Skeleton key={index} className="h-6 w-[80%] my-2" />
         ))}
      </div>
   )
}
