import { Column } from '@tanstack/react-table'

import { FilterOption } from '@/components/Table/data-table-faceted-filter'

type FilterProps<TData> = {
   column: Column<TData, unknown> | undefined
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Filter = ({ column }: FilterProps<any>): FilterOption[] => {
   if (column) {
      const sortedUniqueValues = [...column.getFacetedUniqueValues().keys()]

      const options: FilterOption[] = sortedUniqueValues.map((value) => ({
         value: value,
         label: value,
      }))
      return options
   }
   return []
}
