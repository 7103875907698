import { useQuery } from '@tanstack/react-query'
import { listConversationsFromDB } from '@/api' // replace with actual import

/**
 * Custom hook for fetching a list of users from conversations in the database.
 * This hook leverages `@tanstack/react-query` for asynchronous data fetching and caching.
 * It fetches the list of users involved in conversations.
 *
 * The hook internally uses `listConversationsFromDB`, which calls the backend service
 * responsible for retrieving the conversations data.
 *
 * @param {string} course_name - The name of the course for which to fetch conversations.
 * @param {string} userId - The ID of the user for whom to fetch conversations.
 * @returns An object containing the query result, including the data (list of users), status (loading, error, or success),
 * and methods for query control (refetch, etc.). This enables the consuming component to render UI based on the fetch status
 * and data, handle loading states, and display any potential errors.
 *
 * The `queryKey` is set to ['conversations-list', userId, course_name] to uniquely identify this query's cache entry.
 * The `queryFn` is responsible for executing the fetch operation, with the `signal` parameter used to optionally cancel the request.
 */
const useConversationsList = (course_name: string, userId: string) => {
   return useQuery({
      queryKey: ['conversations-list', userId, course_name],
      queryFn: ({ queryKey, signal }) => {
         if (!course_name || !userId) {
            return Promise.resolve([])
         }
         return listConversationsFromDB(queryKey[1], queryKey[2], signal)
      },
      retry: 1,
   })
}

export default useConversationsList
