import { updateAssigneesInDB } from '@/api'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { toast } from 'sonner'
import { queryClient } from '@/App'

/**
 * Custom hook for mutating assignee data of a course.
 * This hook utilizes a mutation function to update the assignees of a specific course in the database.
 * It leverages react-query's useMutation for asynchronous data mutation with optimistic updates.
 *
 * @returns {UseMutationResult<boolean, unknown, { courseName: string; assignedTo: string[] }, unknown>}
 * The return value is a mutation result object that includes the mutation state and functions to manipulate it.
 *
 * @example
 * const mutation = useAssigneesMutate();
 * mutation.mutate({ courseName: 'ABC123NAA', assignedTo: ['user1', 'user2'] });
 */
const useAssigneesMutate = (): UseMutationResult<
   boolean,
   unknown,
   { courseName: string; assignedTo: string[] },
   unknown
> => {
   return useMutation({
      mutationFn: (updatedCourse) => updateAssigneesInDB(updatedCourse.courseName, updatedCourse.assignedTo),
      onSuccess: (data, variables) => {
         toast.success('Updated course assignees', {
            description: `The assignees for ${variables.courseName} have been updated successfully`,
         })
         queryClient.invalidateQueries({ queryKey: ['courses'], exact: false })
      },
      onError: (error) => {
         toast.error('Failed to update assignees', {
            description: error instanceof Error ? error.message : 'Please try again',
         })
      },
   })
}

export default useAssigneesMutate
