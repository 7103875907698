import { useQuery } from '@tanstack/react-query'
import { getLogDetailsFromDB } from '@/api' // replace with actual import

/**
 * Custom hook for fetching chat API log details from the database.
 * This hook uses the `useQuery` hook from `@tanstack/react-query` to asynchronously fetch the log details
 * based on the provided `courseName`, `date`, `time`, and `fileName`. The `documentPath` is used to locate the log file in the database.
 *
 * @param {string} courseName - The name of the course associated with the log file.
 * @param {string} date - The date of the log file.
 * @param {string} time - The time of the log file.
 * @param {string} fileName - The name of the log file.
 * @returns An object containing the query result. This includes the log details data on success,
 * and provides utilities to manage the query state, such as loading and error states.
 *
 * The `queryFn` is responsible for calling `getLogDetailsFromDB`, which performs the actual data fetching operation.
 * The `queryKey` is constructed using the string 'chat-api-log', the `courseName`, `date`, `time`, and `fileName` to uniquely identify the query.
 * This ensures that the fetched data is specific to the requested log file.
 * The `retry` option is set to 1, indicating that the query will be retried once upon failure before moving to the error state.
 */
const useChatAPILogFile = (courseName: string, date: string, time: string, fileName: string) => {
   return useQuery({
      queryKey: ['chat-api-log', courseName, date, time, fileName],
      queryFn: ({ queryKey, signal }) =>
         getLogDetailsFromDB(queryKey[1], queryKey[2], queryKey[3], queryKey[4], signal),
      retry: 1,
   })
}

export default useChatAPILogFile
