// External libraries
import { useCallback, useMemo } from 'react'
import { useQueries, UseQueryResult } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'

// Hooks and Jotai Atoms
import useCourses from '@/hooks/courses/useCourses'

// Global utilities and constants
import { userAtom } from '@/store/store'

// Services and API calls
import { listDocumentsFromDB } from '@/api'

// Types
import { CourseDocuments } from '@/types'

/**
 * Custom hook for fetching course documents based on the current user's role and courses they are associated with.
 * This hook utilizes `useQueries` from `@tanstack/react-query` to dynamically generate and execute multiple queries
 * based on the user's role (admin or regular user) and the courses they are enrolled in or managing.
 *
 * For an admin, it fetches documents across all courses, while for a regular user, it fetches documents specific to their courses.
 * The hook combines the results of these queries into a single response object, handling loading and error states collectively.
 *
 * @returns {Object} An object containing the combined results of the document queries. This includes:
 * - `data`: An aggregated object of course documents.
 * - `pending`: A boolean indicating if any of the queries are pending.
 * - `loading`: A boolean indicating if any of the queries are in the loading state.
 * - `isError`: A boolean indicating if any of the queries resulted in an error.
 * - `error`: An array of error objects if any of the queries failed.
 *
 * This hook is designed to be used within components that need to display documents related to the courses a user is involved with,
 * providing a streamlined way to handle multiple related queries with varying keys and functions.
 */
export default function useCourseDocuments() {
   const { userId, idToken, isAdmin } = useAtomValue(userAtom)
   const { data: coursesList } = useCourses()

   const combine = useCallback((results: UseQueryResult<CourseDocuments, Error>[]) => {
      const aggregatedErrors = results.filter((result) => result.error).map((result) => result.error)
      return {
         data: results.reduce<CourseDocuments>((acc, result) => result.data || acc, {}),
         pending: results.some((result) => result.isPending),
         loading: results.some((result) => result.isLoading),
         isError: results.find((result) => result.isError),
         error: aggregatedErrors,
      }
   }, [])

   const queries = useMemo(() => {
      // exit early if no courses are available
      if (!coursesList || coursesList.length === 0) {
         return []
      }

      // generate queries based on user role
      return isAdmin
         ? [
              {
                 queryKey: ['documents', userId, 'admin'],
                 queryFn: ({ signal }: { signal: AbortSignal }) => listDocumentsFromDB(userId, 'admin', signal),
                 refetchInterval: 1000 * 60 * 5,
                 refetchIntervalInBackground: true,
              },
           ]
         : coursesList.map((course) => ({
              queryKey: ['documents', userId, course.courseName],
              queryFn: ({ signal }: { signal: AbortSignal }) => listDocumentsFromDB(userId, course.courseName, signal),
              refetchInterval: 1000 * 60 * 5,
              refetchIntervalInBackground: true,
           }))
   }, [isAdmin, userId, idToken, coursesList])

   const results = useQueries({
      queries,
      combine,
   })

   return results
}
