import { useSearchParams } from 'react-router-dom'

/**
 * Custom hook for managing and accessing search parameters related to course logs in the URL.
 * This hook utilizes the `useSearchParams` hook from `react-router-dom` to read and update the URL's search parameters.
 *
 * It specifically manages the following search parameters:
 * - `courseName`: The name of the course to filter logs by. Defaults to an empty string if not specified.
 * - `date`: The date to filter logs by. Defaults to an empty string if not specified.
 * - `pageIndex`: The current page index for pagination. Defaults to 0 if not specified.
 * - `pageSize`: The number of log entries per page. Defaults to 10 if not specified.
 *
 * @returns An object containing the current values of the search parameters and a `setSearchParams` function to update them.
 * The `setSearchParams` function can be used to programmatically update the search parameters in the URL.
 */
export function useLogsSearchParams() {
   const [searchParams, setSearchParams] = useSearchParams()
   const courseName = searchParams.get('courseName') ?? ''
   const date = searchParams.get('date') ?? ''
   const pageIndex = searchParams.get('pageIndex') ?? 0
   const pageSize = searchParams.get('pageSize') ?? 10

   return { courseName, date, pageIndex, pageSize, setSearchParams }
}
