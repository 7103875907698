import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { deleteUserFromDB } from '@/api' // Ensure this is the correct import path
import { queryClient } from '@/App'
import { toast } from 'sonner'

type DeleteUser = {
   userId: string
}

/**
 * Custom hook for deleting a user from the database.
 * Utilizes React Query's `useMutation` for the delete operation, providing a mutation result interface.
 *
 * The hook no longer integrates with the application's global state (using Jotai) to access the current user's ID token.
 * It leverages toast notifications to provide feedback on the operation's success or failure.
 *
 * @returns {UseMutationResult<boolean, Error, DeleteUser, unknown>} An object containing the mutation's state and control methods.
 * - `mutationFn`: Function to perform the delete operation, taking a `DeleteUser` object with the user's ID.
 * - `onSuccess`: Callback that triggers a success toast and invalidates the 'users-list' query to refresh the list post-deletion.
 * - `onError`: Callback that triggers an error toast if the deletion fails.
 *
 * This hook is designed to be used in components where deleting a user is necessary, abstracting the deletion logic
 * and state management for a cleaner component implementation.
 */
const useUserDeleteMutation = (): UseMutationResult<boolean, Error, DeleteUser, unknown> => {
   return useMutation({
      mutationFn: (deleteUser: DeleteUser) => deleteUserFromDB(deleteUser.userId),
      onSuccess: (_, variables) => {
         toast.success('User deleted successfully!', {
            description: `User has been removed from the database: ${variables.userId}`,
         })
         queryClient.invalidateQueries({ queryKey: ['users-list'] })
      },
      onError: (error) => {
         toast.error("Couldn't delete user", {
            description: error instanceof Error ? error.message : 'Please try again',
         })
      },
   })
}

export default useUserDeleteMutation
